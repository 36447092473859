import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-policy-conditions',
  templateUrl: './policy-conditions.component.html',
  styleUrls: ['./policy-conditions.component.scss'],
})
export class PolicyConditionsComponent implements OnInit {
  routeSubscription: Subscription;

  public pages = {
    'privacy-policy': false,
    'cookie-policy': false,
    'terms-brand': false,
    'terms-influencer': false,
  };
  public privacyPolicy = false;
  public cookiePolicy = false;
  public termsBrand = false;
  public termsInfluencer = false;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.routeSubscription = this.route.url.subscribe((route) => {
      this.pages[route[0].path] = true;
    });
  }
}
