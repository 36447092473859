import { Component, Inject, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PaymentsService } from '@app/services/payments.service';
import { SubscriptionModel } from '@app/shared/model';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ChangePlanDialogComponent } from '@app/dialogs/payments-dialog/change-plan-dialog/change-plan-dialog.component';
import { Actions, ofType } from '@ngrx/effects';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.state';
import * as brandActions from '@store/brand';
import { CONFIG } from '@config/configuration';
import { getCurrencySymbol } from '@app/shared/helpers/helpers';
import { TokenService } from '@app/services/token.service';

declare let paypal: any;

export const PlanDescriptionEnum = {
  0: 'It allows you to invite up to 10 influencers from your community, who can become your ambassadors by publishing social content and be immediately rewarded through vouchers. Start inviting your community!',
  1: 'It allows you to invite up to 100 influencers from your community, ensuring the achievement of KPIs through social content publication and word-of-mouth, and easily and quickly rewarding your community with vouchers! Start reaching KPI’s thanks to your community!',
  2: 'It allows you to invite an unlimited number of influencers from your community (even your entire community) and reach the maximum potential audience through social content publication and voucher remuneration. Rock it!',
  3: 'It allows you to invite up to 3 influencers from your community, who can become your ambassadors by publishing social content and be immediately rewarded through vouchers. Start inviting your community!'
};

@Component({
  selector: 'app-payments-dialog',
  templateUrl: './payments-dialog.component.html',
  styleUrls: ['./payments-dialog.component.scss'],
})
export class PaymentsDialogComponent implements OnInit {
  accessToken: string;
  accountPlan: any[];
  currencySymbol: string;
  planDescription = PlanDescriptionEnum;
  plans: any;
  selectedPlan: any;
  subscription: SubscriptionModel;
  isPaymentOngoing = false;

  constructor(
    private toastr: ToastrService,
    public ref: MatDialogRef<PaymentsDialogComponent>,
    private paymentsService: PaymentsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private store: Store<AppState>,
    private actions: Actions,
    private tokenService: TokenService,
) {
  }

  ngOnInit(): void {
    this.accessToken = this.tokenService?.getToken();
    this.subscription = this.data?.subscription;
    this.paymentsService.getPlans().subscribe((res) => {
      this.plans = res;
      if (this.plans?.plan?.length) {
        this.accountPlan = this.plans?.plan.reduce((acc, plan, index) => {
          acc[plan.slug] = {
            ...plan,
            index,
          };
          return acc;
        }, {});
        this.currencySymbol = getCurrencySymbol(this.accountPlan[this.subscription?.plan]?.currency);
      }
      if (!this.subscription?.plan) {
        setTimeout(this.openChangePlanDialog.bind(this));
      }
      this.setPlans(this.subscription);
    });

    if (!window['paypal']) {
      const PAYPAL_CLIENT_ID = CONFIG.payPalClientID;
      this.loadExternalScript(
        `https://www.paypal.com/sdk/js?client-id=${PAYPAL_CLIENT_ID}&vault=true&intent=subscription&disable-funding=credit`
      ).then(() => {
        this.initPayPalButton();
      });
    } else {
      this.initPayPalButton();
    }
  }

  setPlans(subscription) {
    this.selectedPlan = this.plans.plan.find((plan) => {
      return plan.slug === subscription.plan;
    });
  }

  initPayPalButton(): void {
    paypal
      .Buttons({
        style: {
          layout: 'vertical',
          shape: 'pill',
          label: 'paypal',
          fundingicons: false,
        },
        createSubscription: (data, actions) => {
          return actions.subscription.create({
            'plan_id': this.selectedPlan?.paypal_plan_id,
          });
        },
        onApprove: (data, actions) => {
          // this.paymentsService.completeSubscription(data.subscriptionID).subscribe((res) => {
          //   this.toastr.success('Your subscription has been successfully paid');
          //   this.store.dispatch(brandActions.getBrand());
          //   this.ref.close();
          // });
          this.isPaymentOngoing = true;
          this.ref.disableClose = true;
          let attempts = 0;
          const completePayment = () => {
            const myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');
            myHeaders.append('Authorization', `Bearer ${this.accessToken}`);
            const raw = JSON.stringify({
              subscription_id: data.subscriptionID.toString()
            });
            const requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: raw,
            };
            fetch(`${CONFIG.baseUrl}payments/subscriptions/complete`, requestOptions)
              .then(response => response.json())
              .then(result => {
                if (result?.subscription?.success) {
                  this.toastr.success('Your subscription has been successfully paid');
                } else if (result?.subscription?.success === false) {
                  attempts++;
                  if (attempts === 20) {
                    this.toastr.error('Something went wrong');
                  } else {
                    return setTimeout(() => {
                      completePayment();
                    }, 5000);
                  }
                }
                this.isPaymentOngoing = false;
                this.store.dispatch(brandActions.getBrand());
                this.ref.disableClose = false;
                this.ref.close();
                return result;
              })
              .catch(error => {
                this.toastr.error('Something went wrong');
              });
          };
          setTimeout(() => {
            completePayment();
          }, 5000);
        }
      })
      .render('#paypal-button');
  }

  private loadExternalScript(scriptUrl: string): any {
    return new Promise((resolve, reject) => {
      const scriptElement = document.createElement('script');
      scriptElement.src = scriptUrl;
      scriptElement.onload = resolve;
      document.body.appendChild(scriptElement);
    });
  }

  openChangePlanDialog() {
    this.dialog.open(ChangePlanDialogComponent, {
      data: {
        accountPlans: this.plans?.plan || [],
      }
    }).afterClosed().subscribe((res) => {
      if (res?.plan) {
        const host = location.protocol + '//' + location.host;
        const url = host + '/redirect';
        if (this.subscription.id) {
          this.paymentsService.changeActivePlan(this.subscription.id, res.plan, url, host, res?.promocode).subscribe(() => {
              this.store.dispatch(brandActions.getBrand());
              this.actions.pipe(ofType(brandActions.getBrandSuccess), take(1)).subscribe(({ brand }) => {
                this.subscription = brand.subscription;
                this.setPlans(brand.subscription);
                if (res?.promocode) {
                  this.ref.close();
                  this.toastr.success('Your subscription has been successfully activated');
                }
              });
            }
          );
        } else {
          this.paymentsService.createSubscription(res.plan, res?.promocode).subscribe(() => {
            this.store.dispatch(brandActions.getBrand());
            this.actions.pipe(ofType(brandActions.getBrandSuccess), take(1)).subscribe(({ brand }) => {
              this.subscription = brand.subscription;
              this.setPlans(brand.subscription);
              if (res?.promocode) {
                this.ref.close();
                this.toastr.success('Your subscription has been successfully activated');
              }
            });
          });
        }
      }
    });
  }
}
