export class UserModel {
  currency: string;
  email: string;
  firstName: string;
  id: number;
  invoice: boolean;
  isActive: boolean;
  isAdditionalBrandUser: number | null;
  isBrand: boolean;
  isBrandOwner: boolean;
  isFirstLogin: boolean;
  lastName: string;
  password?: string;
  username: string;
  promocode?: string;
}
