<!--<header>-->
<!--  <div class="wrapper">-->
<!--    <div class="header header-left">-->
<!--      <a routerLink='/' class="logo">-->
<!--        <img class="logo__img" src="assets/img/icons/logo-white.png" alt="">-->
<!--        <span class="logo__text">flytrendy</span>-->
<!--      </a>-->

<!--      <div *ngIf='!isFeedbackPage' class="header__block" [ngClass]='{"active": openedMenu}'>-->
<!--        <ul class="menu">-->
<!--          <li class="menu__item">-->
<!--            <a (click)='goToRoute("results")' class="menu__link">Performance Campaign</a>-->
<!--          </li>-->
<!--          <li class="menu__item">-->
<!--            <a (click)='goToRoute("platform")' class="menu__link">SaaS Agency</a>-->
<!--          </li>-->
<!--          <li class="menu__item">-->
<!--            <a (click)='goToRoute("saas")' class="menu__link">Customer Reward Platform</a>-->
<!--          </li>-->
<!--          <li class="menu__item">-->
<!--            <a (click)='goToRoute("influencer")' class="menu__link">Creator</a>-->
<!--          </li>-->
<!--&lt;!&ndash;          <li class="menu__item">&ndash;&gt;-->
<!--&lt;!&ndash;            <a (click)='goToRoute("contact")' class="menu__link">Contattaci</a>&ndash;&gt;-->
<!--&lt;!&ndash;          </li>&ndash;&gt;-->
<!--        </ul>-->

<!--        <button (click)='goToRoute("campaign")' type="button" class="btn small primary-btn-filled">-->
<!--          <span class="btn__text">{{'topBar.createNewCampaign' | translate}}</span>-->
<!--        </button>-->

<!--        <button type="button" class="btn-text primary-btn-text" (click)='openLoginDialog(); openedMenu = false;'>-->
<!--          <span class="btn-text__text">Login</span>-->
<!--          <svg class="btn-text__svg" width="17" height="20" viewBox="0 0 17 20" fill="currentColor">-->
<!--            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.49446 20C2.83149 17.5701 0 15.1458 0 12.7273V3.63636C0 2.93855 0.278051 2.77638 1.04758 2.32754C1.28058 2.19164 1.55863 2.02946 1.88766 1.81818C2.04036 1.72013 5.25072 0 8.49446 0C11.3215 0 13.6855 0.909091 15.1013 1.81818C15.4303 2.02946 15.7083 2.19164 15.9413 2.32754C16.7109 2.77638 16.9889 2.93855 16.9889 3.63636C17.0075 3.83384 16.9984 8.03319 16.9925 10.7219V10.7225L16.9925 10.7258C16.9905 11.6411 16.9889 12.3809 16.9889 12.7273C16.9889 15.1515 14.1574 17.5758 8.49446 20ZM15.1013 12.7272C15.1013 12.5178 15.1018 12.167 15.103 11.5951L15.1068 9.81941C15.108 9.24495 15.109 8.75198 15.1098 8.28155C15.1133 6.25233 15.1123 4.51068 15.1103 3.97201L14.9806 3.89451C14.6754 3.71213 14.2776 3.47443 14.0542 3.33093C12.6608 2.43622 10.6277 1.81812 8.49446 1.81812C6.45398 1.81812 3.80234 2.77382 2.93474 3.33093C2.71128 3.47443 2.31348 3.71213 2.00827 3.8945L1.88766 3.9666V12.7272C1.88766 14.0985 3.99155 15.9715 8.49533 18.0017C12.9986 15.9761 15.1013 14.1038 15.1013 12.7272ZM11.6023 6.62988L7.55058 10.5325L5.38649 8.44806L4.05171 9.73371L7.55058 13.1038L12.9371 7.91553L11.6023 6.62988Z"/>-->
<!--          </svg>-->
<!--        </button>-->

<!--        <div class="soc-seti">-->
<!--          <a [href]="socialLinks.facebook" class="soc-seti__link">-->
<!--            <svg width="21" height="20" viewBox="0 0 21 20" fill="currentColor">-->
<!--              <path fill-rule="evenodd" clip-rule="evenodd" d="M2.99121 0H18.9912C20.0958 0 20.9912 0.89543 20.9912 2V18C20.9912 19.1046 20.0958 20 18.9912 20H2.99121C1.88664 20 0.991211 19.1046 0.991211 18V2C0.991211 0.89543 1.88664 0 2.99121 0ZM2.99121 2V18H18.9912V2H2.99121ZM10.1243 10.0044H11.9912V16H13.9912V10.0044H15.9736V8.00439H13.9912V7C13.9912 6.44772 14.4389 6 14.9912 6H15.9912V4H14.9912C13.3344 4 11.9912 5.34315 11.9912 7V8.00439H10.1243V10.0044Z"/>-->
<!--            </svg>-->
<!--          </a>-->
<!--          <a [href]="socialLinks.instagram" class="soc-seti__link">-->
<!--            <svg width="21" height="20" viewBox="0 0 21 20" fill="currentColor">-->
<!--              <path fill-rule="evenodd" clip-rule="evenodd" d="M14.9912 0H6.99121C3.6775 0 0.991211 2.68629 0.991211 6V14C0.991211 17.3137 3.6775 20 6.99121 20H14.9912C18.3049 20 20.9912 17.3137 20.9912 14V6C20.9912 2.68629 18.3049 0 14.9912 0ZM2.99121 6C2.99121 3.79086 4.78207 2 6.99121 2H14.9912C17.2003 2 18.9912 3.79086 18.9912 6V14C18.9912 16.2091 17.2003 18 14.9912 18H6.99121C4.78207 18 2.99121 16.2091 2.99121 14V6ZM10.9912 15C8.22979 15 5.99121 12.7614 5.99121 10C5.99121 7.23858 8.22979 5 10.9912 5C13.7526 5 15.9912 7.23858 15.9912 10C15.9912 12.7614 13.7526 15 10.9912 15ZM10.9912 13C12.6481 13 13.9912 11.6569 13.9912 10C13.9912 8.34315 12.6481 7 10.9912 7C9.33436 7 7.99121 8.34315 7.99121 10C7.99121 11.6569 9.33436 13 10.9912 13ZM16.9912 5C16.9912 5.55228 16.5435 6 15.9912 6C15.4389 6 14.9912 5.55228 14.9912 5C14.9912 4.44772 15.4389 4 15.9912 4C16.5435 4 16.9912 4.44772 16.9912 5Z"/>-->
<!--            </svg>-->
<!--          </a>-->
<!--          <a [href]="socialLinks.linkedin" class="soc-seti__link">-->
<!--            <svg width="21" height="20" viewBox="0 0 21 20" fill="currentColor">-->
<!--              <path fill-rule="evenodd" clip-rule="evenodd" d="M2.99121 0H18.9912C20.0958 0 20.9912 0.89543 20.9912 2V18C20.9912 19.1046 20.0958 20 18.9912 20H2.99121C1.88664 20 0.991211 19.1046 0.991211 18V2C0.991211 0.89543 1.88664 0 2.99121 0ZM2.99121 2V18H18.9912V2H2.99121ZM11.9912 7C11.4735 7 10.9265 7.15826 10.4434 7.45215L9.99121 7H8.99121V14H10.9912V10C10.9912 9.42425 11.5852 9 11.9912 9H12.9912C13.3973 9 13.9912 9.42425 13.9912 10V14H15.9912V10C15.9912 8.14718 14.3852 7 12.9912 7H11.9912ZM6.99121 6C7.5435 6 7.99121 5.55228 7.99121 5C7.99121 4.44772 7.5435 4 6.99121 4C6.43893 4 5.99121 4.44772 5.99121 5C5.99121 5.55228 6.43893 6 6.99121 6ZM5.99121 7V14H7.99121V7H5.99121Z"/>-->
<!--            </svg>-->
<!--          </a>-->
<!--          <a [href]="socialLinks.youtube" class="soc-seti__link">-->
<!--            <svg width="21" height="16" viewBox="0 0 21 16" fill="currentColor">-->
<!--              <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1036 13.5713C20.7248 12.5068 20.9902 10.7901 20.9902 7.99772C20.9902 5.21643 20.7273 3.5098 20.0995 2.41666C19.4704 1.28151 18.7241 0.921204 17.2432 0.834722C16.2322 0.766196 13.7196 0.727539 10.9927 0.727539C8.25988 0.727539 5.74609 0.766181 4.72751 0.835259C3.25489 0.92141 2.50852 1.28251 1.87381 2.42081C1.25513 3.51018 0.990234 5.22515 0.990234 8.0079C0.990234 10.7748 1.25641 12.4985 1.87925 13.5842C2.5057 14.7079 3.24017 15.0646 4.72533 15.1689C5.79274 15.2314 8.4488 15.273 10.9927 15.273C13.5308 15.273 16.1856 15.2314 17.2432 15.1696C18.7421 15.0647 19.4764 14.7073 20.1036 13.5713ZM17.1288 2.64926C18.0551 2.70339 18.2269 2.78665 18.5205 3.3181C18.948 4.05842 19.1721 5.50726 19.1721 8.00788C19.1721 10.4896 18.9468 11.9464 18.5225 12.6739C18.2279 13.207 18.0573 13.29 17.1267 13.3552C16.1256 13.4136 13.4966 13.4548 10.9927 13.4548C8.48308 13.4548 5.85273 13.4136 4.84208 13.3545C3.92297 13.2899 3.74992 13.2059 3.46181 12.6892C3.03465 11.9444 2.80842 10.4794 2.80842 7.9977C2.80842 5.51751 3.03345 4.0606 3.45832 3.31245C3.75091 2.78776 3.92518 2.70345 4.84214 2.64978C5.81303 2.58398 8.30318 2.5457 10.9927 2.5457C13.6764 2.5457 16.1653 2.58399 17.1288 2.64926ZM9.17212 5.27246L13.7176 7.99973L9.17212 10.727V5.27246Z"/>-->
<!--            </svg>-->
<!--          </a>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="menu-btn" (click)='toggleMenu()'>-->
<!--        <div class="menu-btn__hamburger" [ngClass]='{"active": openedMenu}'></div>-->
<!--      </div>-->

<!--      <div class="overlay-menu"></div>-->
<!--    </div>-->
<!--  </div>-->
<!--</header>-->
<header>
  <div class="wrapper-large">
    <div class="header">
      <button type="button" class="menu-btn">
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M25.0003 45.8337C36.4587 45.8337 45.8337 36.4587 45.8337 25.0003C45.8337 13.542 36.4587 4.16699 25.0003 4.16699C13.542 4.16699 4.16699 13.542 4.16699 25.0003C4.16699 36.4587 13.542 45.8337 25.0003 45.8337Z" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M19.1045 30.8962L30.8962 19.1045" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M30.8962 30.8962L19.1045 19.1045" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
      <a href="/" class="logo">
        <img src="assets/images/logo.svg" alt="logo">
      </a>
<!--      <div class="social-network">-->
<!--        <a href="#" class="social-network__link" target="_blank">-->
<!--          <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">-->
<!--            <path d="M14.75 22.75H10.25V15.5H9.29999C8.71999 15.5 8.25 15.03 8.25 14.45V12.55C8.25 11.97 8.71999 11.5 9.29999 11.5H10.25V9C10.25 6.93 11.93 5.25 14 5.25H16.7C17.28 5.25 17.75 5.71999 17.75 6.29999V8.70001C17.75 9.28001 17.28 9.75 16.7 9.75H14.75V11.5H16.63C16.95 11.5 17.24 11.64 17.44 11.88C17.64 12.12 17.72 12.44 17.66 12.75L17.28 14.65C17.18 15.14 16.75 15.49 16.25 15.49H14.75V22.75ZM11.75 21.25H13.25V14H15.89L16.09 13H13.26V9.29999C13.26 8.71999 13.73 8.25 14.31 8.25H16.26V6.75H14C12.76 6.75 11.75 7.76 11.75 9V13H9.75V14H11.75V21.25Z"/>-->
<!--            <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"/>-->
<!--          </svg>-->
<!--        </a>-->
<!--        <a href="#" class="social-network__link" target="_blank">-->
<!--          <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">-->
<!--            <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"/>-->
<!--            <path d="M12 16.25C9.66 16.25 7.75 14.34 7.75 12C7.75 9.66 9.66 7.75 12 7.75C14.34 7.75 16.25 9.66 16.25 12C16.25 14.34 14.34 16.25 12 16.25ZM12 9.25C10.48 9.25 9.25 10.48 9.25 12C9.25 13.52 10.48 14.75 12 14.75C13.52 14.75 14.75 13.52 14.75 12C14.75 10.48 13.52 9.25 12 9.25Z"/>-->
<!--            <path d="M17 7.4999C16.87 7.4999 16.74 7.4699 16.62 7.4199C16.5 7.3699 16.39 7.2999 16.29 7.2099C16.2 7.1099 16.12 6.9999 16.07 6.8799C16.02 6.7599 16 6.6299 16 6.4999C16 6.3699 16.02 6.2399 16.07 6.1199C16.13 5.9899 16.2 5.8899 16.29 5.7899C16.34 5.7499 16.39 5.6999 16.44 5.6699C16.5 5.6299 16.56 5.5999 16.62 5.5799C16.68 5.5499 16.74 5.5299 16.81 5.5199C17.13 5.4499 17.47 5.5599 17.71 5.7899C17.8 5.8899 17.87 5.9899 17.92 6.1199C17.97 6.2399 18 6.3699 18 6.4999C18 6.6299 17.97 6.7599 17.92 6.8799C17.87 6.9999 17.8 7.1099 17.71 7.2099C17.61 7.2999 17.5 7.3699 17.38 7.4199C17.26 7.4699 17.13 7.4999 17 7.4999Z"/>-->
<!--          </svg>-->

<!--        </a>-->
<!--        <a href="#" class="social-network__link" target="_blank">-->
<!--          <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">-->
<!--            <path d="M7 5.88C7 5.63467 7.08533 5.42667 7.256 5.256C7.43733 5.08533 7.64533 5 7.88 5C8.11467 5 8.31733 5.08533 8.488 5.256C8.66933 5.42667 8.76 5.63467 8.76 5.88C8.76 6.12533 8.66933 6.33333 8.488 6.504C8.31733 6.67467 8.11467 6.76 7.88 6.76C7.64533 6.76 7.43733 6.67467 7.256 6.504C7.08533 6.33333 7 6.12533 7 5.88ZM8.488 16.744C8.31733 16.9147 8.11467 17 7.88 17C7.64533 17 7.43733 16.9147 7.256 16.744C7.08533 16.5733 7 16.3653 7 16.12V9.56C7 9.31467 7.08533 9.10667 7.256 8.936C7.43733 8.76533 7.64533 8.68 7.88 8.68C8.11467 8.68 8.31733 8.76533 8.488 8.936C8.66933 9.10667 8.76 9.31467 8.76 9.56V16.12C8.76 16.3653 8.66933 16.5733 8.488 16.744Z"/>-->
<!--            <path d="M11.4675 16.76C11.3075 16.92 11.1102 17 10.8755 17C10.6408 17 10.4382 16.92 10.2675 16.76C10.1075 16.6 10.0275 16.4027 10.0275 16.168V9.464C10.0275 9.25067 10.1022 9.06933 10.2515 8.92C10.4115 8.76 10.5982 8.68 10.8115 8.68C11.0248 8.68 11.2062 8.76 11.3555 8.92C11.5155 9.06933 11.6008 9.25067 11.6115 9.464L11.6275 9.704C11.6275 9.71467 11.6328 9.72 11.6435 9.72C11.6542 9.72 11.6648 9.71467 11.6755 9.704C12.4328 8.91467 13.2968 8.52 14.2675 8.52C15.2382 8.52 15.9475 8.79733 16.3955 9.352C16.8435 9.896 17.0675 10.792 17.0675 12.04V16.184C17.0675 16.408 16.9875 16.6 16.8275 16.76C16.6675 16.92 16.4755 17 16.2515 17C16.0275 17 15.8302 16.92 15.6595 16.76C15.4995 16.6 15.4195 16.408 15.4195 16.184V12.28C15.4195 11.32 15.3022 10.6693 15.0675 10.328C14.8328 9.976 14.4062 9.8 13.7875 9.8C13.2862 9.8 12.8115 10.024 12.3635 10.472C11.9262 10.9093 11.7075 11.3787 11.7075 11.88V16.168C11.7075 16.4027 11.6275 16.6 11.4675 16.76Z"/>-->
<!--            <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"/>-->
<!--          </svg>-->
<!--        </a>-->
<!--        <a href="#" class="social-network__link" target="currentColor">-->
<!--          <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">-->
<!--            <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"/>-->
<!--            <path d="M10.59 15.53C10.3 15.53 10.02 15.46 9.77002 15.32C9.19002 14.99 8.84998 14.32 8.84998 13.49V10.53C8.84998 9.69997 9.18002 9.02999 9.77002 8.69999C10.35 8.36999 11.1 8.42996 11.81 8.85996L14.28 10.34C14.96 10.75 15.35 11.36 15.35 12.01C15.35 12.66 14.96 13.27 14.28 13.68L11.81 15.16C11.4 15.4 10.98 15.53 10.59 15.53ZM10.59 9.96998C10.55 9.96998 10.52 9.97997 10.5 9.98997C10.43 10.03 10.35 10.21 10.35 10.52V13.48C10.35 13.78 10.43 13.96 10.5 14.01C10.58 14.05 10.77 14.03 11.03 13.87L13.5 12.39C13.75 12.24 13.84 12.09 13.84 12C13.84 11.91 13.75 11.77 13.5 11.61L11.03 10.13C10.85 10.02 10.69 9.96998 10.59 9.96998Z"/>-->
<!--          </svg>-->
<!--        </a>-->
<!--      </div>-->
      <div class="nav">
        <a routerLink="about" class="nav__link">About us</a>
        <a href="#" class="nav__link" (click)="openDemoDialog()">Demo</a>
        <a routerLink="contact" class="nav__link">Contact us</a>
      </div>
      <button type="button" class="btn btn--bg btn--primary" (click)="openRegistrationDialog()">Create account</button>
      <button (click)='openLoginDialog(); openedMenu = false;' type="button" class="btn-text btn--primary">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 10.75C17.59 10.75 17.25 10.41 17.25 10V8C17.25 4.85 16.36 2.75 12 2.75C7.64 2.75 6.75 4.85 6.75 8V10C6.75 10.41 6.41 10.75 6 10.75C5.59 10.75 5.25 10.41 5.25 10V8C5.25 5.1 5.95 1.25 12 1.25C18.05 1.25 18.75 5.1 18.75 8V10C18.75 10.41 18.41 10.75 18 10.75Z"/>
          <path d="M12 19.25C10.21 19.25 8.75 17.79 8.75 16C8.75 14.21 10.21 12.75 12 12.75C13.79 12.75 15.25 14.21 15.25 16C15.25 17.79 13.79 19.25 12 19.25ZM12 14.25C11.04 14.25 10.25 15.04 10.25 16C10.25 16.96 11.04 17.75 12 17.75C12.96 17.75 13.75 16.96 13.75 16C13.75 15.04 12.96 14.25 12 14.25Z"/>
          <path d="M17 22.75H7C2.59 22.75 1.25 21.41 1.25 17V15C1.25 10.59 2.59 9.25 7 9.25H17C21.41 9.25 22.75 10.59 22.75 15V17C22.75 21.41 21.41 22.75 17 22.75ZM7 10.75C3.42 10.75 2.75 11.43 2.75 15V17C2.75 20.57 3.42 21.25 7 21.25H17C20.58 21.25 21.25 20.57 21.25 17V15C21.25 11.43 20.58 10.75 17 10.75H7Z"/>
        </svg>
        <span>Login</span>
      </button>
    </div>
    <div class="header-mobile">
      <a href="/" class="logo">
        <img src="assets/images/logo.svg" alt="logo">
      </a>
      <button type="button" class="menu-btn">
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.25 14.583H43.75" stroke="currentColor" stroke-width="3" stroke-linecap="round"/>
          <path d="M6.25 25H43.75" stroke="currentColor" stroke-width="3" stroke-linecap="round"/>
          <path d="M6.25 35.417H43.75" stroke="currentColor" stroke-width="3" stroke-linecap="round"/>
        </svg>
      </button>
    </div>
    <div class="overlay" style="display: none"></div>
  </div>
</header>
