import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthService } from './services/auth.service';
import { getUser } from './store';
import { AppState } from '@store/app.state';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { Location } from '@angular/common';
import { NavigationStart, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private store: Store<AppState>,
    private ccService: NgcCookieConsentService,
    private location: Location,
    private router: Router,
    private translate: TranslateService,
    @Inject(DOCUMENT) document
  ) {}

  ngOnInit(): void {
    if (this.authService.isLoggedIn) {
      this.store.dispatch(getUser());
    }
    localStorage.setItem('language', 'en');
    this.translate.use('en');

  }
}
