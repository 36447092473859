import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CONFIG } from '@config/configuration';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  constructor(private http: HttpClient) {}

  getPlans(): Observable<any> {
    return this.http.get<any>(CONFIG.baseUrl + 'payments/plans');
  }

  createSubscription(planSlug, promoCode) {
    const body = promoCode ? {
      plan: planSlug,
      promocode: promoCode,
    } : {
      plan: planSlug,
    }
    return this.http.post(CONFIG.baseUrl + 'payments/subscriptions', body);
  }

  // completeSubscription(subscription_id) {
  //   console.log('completeSubscription');
  //   return this.http.post(CONFIG.baseUrl + 'payments/subscriptions/complete', { subscription_id });
  // }

  cancelSubscription(subscriptionId: number) {
    return this.http.delete(CONFIG.baseUrl + `payments/subscriptions/${subscriptionId}`);
  }

  changeActivePlan(subscriptionId: number, newPlan: string, onApproveUrl: string, onCancelUrl: string, promocode?: string) {
    const body = promocode ? { plan: newPlan, promocode } : { plan: newPlan };
    return this.http.put(CONFIG.baseUrl + `payments/subscriptions/${subscriptionId}?on_approve=${onApproveUrl}&on_cancel=${onCancelUrl}`, body);
  }

  completeUpdatePlan() {
    return this.http.post(CONFIG.baseUrl + 'payments/subscriptions/complete-update', {})
  }
}
