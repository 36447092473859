import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Meta, Title } from '@angular/platform-browser';
import { HelperService } from '@app/services/helper.service';

enum videoLinks {
  'it' = 'https://s3.eu-central-1.amazonaws.com/flytrendy.com/videos/Customer+Reward+Platform.mp4',
  'en' = 'https://flytrendy-assets.s3.eu-central-1.amazonaws.com/static/videos/SpotFlyTrendyBreveEN.mp4',
}

@Component({
  selector: 'app-saas',
  templateUrl: './saas.component.html',
  styleUrls: ['./saas.component.scss'],
})
export class SaasComponent implements OnInit {
  linkToVideo = videoLinks[this.translate.currentLang];
  @ViewChild('video') video: ElementRef<HTMLVideoElement>;

  constructor(
    private title: Title,
    private meta: Meta,
    private helperService: HelperService,
    private translate: TranslateService
  ) {}

  getVideoLink(): void {
    const lang = this.translate.currentLang ? this.translate.currentLang : this.translate.defaultLang;
    this.linkToVideo = videoLinks[lang];
    this.video?.nativeElement.load();
  }

  openDemoDialog(): void {
    this.helperService.openDemoDialog(false);
  }

  ngOnInit(): void {
    this.title.setTitle('Unica piattaforma di social reward-step');

    this.meta.updateTag({
      name: 'keyword',
      content:
        'Piattaforma automatizzata reward-step, martech, creator, loyalty, influencer, e-commerce, new revenues, nuove vendite, fidelizzazione del crm, database, brand ambassadors, kpi garantiti, ugc, cpc garantiti, creazione contenuti, attivazione campagna.',
    });

    this.meta.updateTag({
      name: 'description',
      content: 'Unica Piattaforma interamente automatizzata di Reward che garantisce nuovi ricavi',
    });

    this.getVideoLink();
    this.translate.onLangChange.subscribe(() => this.getVideoLink());
  }
}
