<section class="contact-us">
  <div class="wrapper">
    <h1>About Us</h1>
    <h2>Thanks to our years of experience working with content creators and managing communities side by side with brands, we have understood how crucial it is to have and nurture a brand community. That's why we have combined our know-how and passion to create this unique and intuitive technology to meet the needs of brands and leverage the potential of your community to increase brand awareness and enhance loyalty with activities that have positive cash flow/trackable and guaranteed results.
      <br>
      Imagine having real customers who love your products and are willing to engage with your Brand beyond just your product purchase. Why not let your brand value be expressed through the voice and creativity of your community, which has already chosen you and knows you, instead of reaching out to external users who don't have the same connection with you?
      <br><br>
      Subscribe Social Collabz, share and engage
    </h2>
  </div>
</section>
