import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { Location } from '@angular/common';
import { LoginDialogComponent } from '@app/landing/dialogs/login/login-dialog/login-dialog.component';
import { DialogService } from '@ngneat/dialog';
import { Router } from '@angular/router';
import { CONFIG } from '@config/configuration';
import {RegistrationDialogComponent} from "@app/dialogs/registration-dialog/registration-dialog.component";
import {RequestDemoComponent} from "@app/dialogs/request-demo/request-demo.component";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
})
export class MenuComponent implements OnInit {
  socialLinks = CONFIG.platform.socialLinks;
  isFeedbackPage = false;

  constructor(
    private dialog: DialogService,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private router: Router,
    private location: Location
  ) {}
  openedMenu = false;

  ngOnInit(): void {
    this.router.events.subscribe(() => {
      this.isFeedbackPage = !!this.location.path().includes('services/feedback');
    });
  }

  openLoginDialog(): void {
    const dialogRef = this.dialog.open(LoginDialogComponent, {
      closeButton: true,
    });
  }

  goToRoute(url: string): void {
    this.toggleMenu(false);
    this.router.navigate([`/${url}`]);
  }
  toggleMenu(close?: boolean): void {
    this.openedMenu = close === false ? false : !this.openedMenu;
    const element = this.elementRef.nativeElement.querySelectorAll('.overlay-menu');
    const display = this.openedMenu ? 'block' : 'none';
    this.renderer.setStyle(element[0], 'display', display);
  }

  openRegistrationDialog() {
    const dialogRef = this.dialog.open(RegistrationDialogComponent, {
      closeButton: true,
      width: '1200px'
    });
  }

  openDemoDialog() {
    this.dialog.open(RequestDemoComponent)
  }
}
