<section class="typical-page">
  <div class="wrapper">
    <div class="typical-page__block">
      <div class="typical-page__group">
        <span class="typical-page__subtitle">{{resultMessage}}</span>
      </div>

      <a routerLink='/' class="btn big primary-btn-filled">
        <span class="btn__text">home page</span>
      </a>

      <img src="assets/img/video-box_bg.png" alt="" class="typical-page__bg">
    </div>
  </div>
</section>
