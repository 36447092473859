import { environment } from '@env/environment';

export const CONFIG = Object({
  baseUrl: environment.baseUrl,
  S3_IMAGES: environment.S3_IMAGES,
  API_HOST: 'https://api.socialcollabz.com',
  API_PREFIX: 'api',
  clientId: 'GKtIF2K8fmOYWsrMDdOSB7rbCcU6sprzqZb20F7I',
  clientSecret:
    'WsDsdEfQ0LkjFxoFTjlAkB9KZRyrISFWDJccPFuRCxy8gieYHWlPuHxCmHlaYO9HpsOxTRbot6AOxMasgnmJEVLyL4vuS3X3cePxUVX2yrCI9DdHNVMNKh8KkpRGEwxT',
  platformPrefix: 'sc',
  platformName: 'sc_platform',
  isAgency: false,
  payPalClientID: 'AYCghfjwRI8CD4q2bR3PKQ_PW9WjNzzhmX-u_YOD76LYwfOGHOhn-bTHhryDKzpFS_iK0MOAjMGyE1hT',
  platform: {
    techFees: 0.5,
    iva: 0.22,
    adText: '#sponsored',
    defaultLanguage: 'it',
    name: 'Social Collabz',
    languages: {
      en: {
        name: 'English',
        nativeName: 'English',
      },
      it: {
        name: 'Italian',
        nativeName: 'Italiano',
      },
    },
    interests: {
      array1: [
        { name: 'Animals', id: 'animals' },
        { name: 'Music & Movies', id: 'music' },
        { name: 'Photography', id: 'photography' },
      ],
      array2: [
        { name: 'Social & Family', id: 'social' },
        { name: 'Travel & Cars', id: 'travel' },
        { name: 'Education & Technology', id: 'education' },
      ],
      array3: [
        { name: 'Books, Magazines & Games', id: 'books' },
        { name: 'Food, Lifestyle & Events', id: 'food' },
        { name: 'Sports, Fitness & Wellbeing', id: 'sports' },
        { name: 'Online Shopping & Entertainment', id: 'online' },
      ],
    },
    communityDemographics: {
      gender: [{ male: 35 }, { female: 65 }],
      influencer_total_followers: { facebook: 10.44, tiktok: 0, instagram: 89.56 },
      interests: {
        Photography: 12,
        'Sports, Fitness & Wellbeing': 14,
        'Education & Technology': 7,
        'Online Shopping & Entertainment': 11,
        'Music & Movies': 13,
        'Books, Magazines & Games': 7,
        'Food, Lifestyle & Events': 11,
        'Social & Family': 10,
        'Travel & Cars': 13,
        Animals: 2,
      },
      age_categories: { from_18_to_30: 59, from_31_to_40: 29, '40+': 12 },
      regions: {
        Veneto: 5.33,
        Liguria: 1.9,
        Calabria: 4.13,
        'Emilia-Romagna': 6.48,
        Lombardia: 18.3,
        "Valle d'Aosta": 0.75,
        Marche: 2,
        'Trentino-Alto Adige': 0.97,
        Sicilia: 9.95,
        Campania: 13.7,
        Puglia: 6.4,
        Basilicata: 1.33,
        Abruzzo: 3.22,
        'Friuli-Venezia Giulia': 1.42,
        Piemonte: 6.97,
        Toscana: 3.77,
        Lazio: 9.35,
        Umbria: 1.24,
        Molise: 0.66,
        Sardegna: 2.13,
      },
    },
    countries: [
      {
        country_code: 'IT',
        country_name: 'Italy',
        dialling_code: 39,
        country_locale: 'country_italy',
        index: 13,
      },
    ],
    brand_name_new_campaign: true,
    dos: [],
    donts: [],
    privateBrands: [
      {
        name: 'Conde Nast',
        id: 1594,
        logo: 'assets/img/icons/conde_nast.png',
        logoClass: 'logo__condenast',
      },
    ],
    contactus: {
      defaultSubject: '',
    },
    campaign: {
      campaign_wallet: false,
    },
    socialLinks: {
      facebook: 'https://www.facebook.com/FlyTrendy',
      instagram: 'https://www.instagram.com/flytrendy/',
      linkedin: 'https://www.linkedin.com/company/flytrendy/',
      youtube: 'https://www.youtube.com/channel/UC_dtmLHkQXsatj3JOpOeoSA',
    },
    chartColors: ['#47136B', '#4566E1'],
  },
});
