<div class="modal modal-login modal-reg">
  <div class="modal-reg__header">
    <div class="modal-reg__caption-box">
      <span class="h1">Request demo</span>
    </div>
    <span class="h2">Book a call with one of our expert and receive an in-depth, personalized demonstration of our platform.</span>
  </div>
  <div class="modal-reg__main">
    <form [formGroup]="requestDemoForm" (ngSubmit)="onFormSubmit($event)">
      <div class="modal__wrapper">
        <div class="modal-reg__row">
          <div class="modal-reg__col">
            <div class="form__box">
              <span class="form__label">Name</span>
              <input type="text" class="form__input" formControlName='first_name' email='true'>
              <span class="form__valid" *ngIf="!requestDemoForm.get('first_name').valid && requestDemoForm.get('first_name').touched">Please enter your name</span>
            </div>
            <div class="form__box">
              <span class="form__label">Email</span>
              <input type="email" class="form__input" formControlName='email' email='true'>
              <span class="form__valid" *ngIf="!requestDemoForm.get('email').valid && requestDemoForm.get('email').touched">Please enter valid Email</span>
            </div>
          </div>
          <div class="modal-reg__col">
            <div class="form__box">
              <span class="form__label">Phone</span>
              <input type="number" class="form__input" formControlName='phone'>
              <span class="form__valid" *ngIf="!requestDemoForm.get('phone').valid && requestDemoForm.get('phone').touched">Please, enter your phone</span>
            </div>
          </div>
        </div>
      </div>
      <button type="submit" class="btn btn--bg btn--primary btn--modal">Request</button>
    </form>
  </div>
</div>
