import { CampaignDto } from '@app/shared/dto';
import { CampaignModel, SocialPlatforms } from '@app/shared/model';

export class CampaignAdapter {
  public static createCampaign(campaignResponse: CampaignDto): CampaignModel {
    if (!campaignResponse) {
      return null;
    }

    const socialPlatforms = this.createSocialPlatforms(campaignResponse);
    const model = new CampaignModel();

    model.ageMax = campaignResponse.ageMax;
    model.ageMin = campaignResponse.ageMin;
    model.brandCompanyName = campaignResponse.brand_comp_name;
    model.callAction = campaignResponse.call_action;
    model.campaignLink = campaignResponse.campaign_link;
    model.campaignLogoUrl = campaignResponse.campaign_logo_url;
    model.campaignSpent = +campaignResponse.campaign_spent;
    model.campaignWallet = campaignResponse.campaign_wallet;
    model.campaignWalletType = campaignResponse.campaign_wallet_type;
    model.contentLove = campaignResponse.content_love;
    model.createdDatetime = campaignResponse.created_datetime;
    model.dontDos = campaignResponse.dont_dos;
    model.language = campaignResponse.language;
    model.gender = campaignResponse.gender;
    model.heroImageUrl = campaignResponse.hero_image_url;
    model.id = campaignResponse.id;
    model.infoDemographic = campaignResponse.info_demographic;
    model.interests = campaignResponse.interests;
    model.linkedinInterests = campaignResponse.linkedin_interests;
    model.isLive = campaignResponse.is_live;
    model.moodImagesCount = campaignResponse.mood_images_count;
    model.openUntilDate = campaignResponse.open_until_date;
    model.socialPlatforms = socialPlatforms;
    model.platformName = campaignResponse.platform_name;
    model.postSubmissions = campaignResponse.post_submissions;
    model.postsAccepted = campaignResponse.posts_accepted;
    model.preTags = campaignResponse.pre_tags;
    model.proCategorySecondary = campaignResponse.pro_category_secondary;
    model.campaignName = campaignResponse.pro_comp_name;
    model.campaignDescription = campaignResponse.pro_desc;
    model.pubGuides = campaignResponse.pub_guides;
    model.publishBriefApp = campaignResponse.publish_brief_app;
    model.responseTime = campaignResponse.response_time;
    model.responseTimeDate = campaignResponse.response_time_date;
    model.reviewed = campaignResponse.reviewed;
    model.summarySent = campaignResponse.summary_sent;
    model.updatedDate = campaignResponse.updated_date;
    model.userEmail = campaignResponse.user;
    model.objective = campaignResponse.objective;
    model.showPrices = campaignResponse.show_prices;
    model.totalPostsCosts = campaignResponse.total_posts_costs || null;
    model.campaignBudget = campaignResponse.campaign_budget || null;
    model.moodBoardUrls = campaignResponse.mood_board_urls || null;
    model.brandEmail = campaignResponse.brand_owner || null;
    model.reward = campaignResponse.reward || null;
    model.vouchers = campaignResponse.vouchers;

    if (campaignResponse.private_influencers && campaignResponse.private_influencers.length) {
      model.privateInfluencers = campaignResponse.private_influencers;
    }

    return model;
  }

  private static createSocialPlatforms(campaignResponse: CampaignDto): SocialPlatforms {
    const {
      platform_fb,
      platform_insta,
      platform_insta_story,
      platform_tiktok,
      platform_reels,
      platform_linkedin,
    } = campaignResponse;

    return {
      platformFb: platform_fb,
      platformInsta: platform_insta,
      platformInstaStory: platform_insta_story,
      platformTiktok: platform_tiktok,
      platformReels: platform_reels,
      platformLinkedin: platform_linkedin,
    };
  }
}
