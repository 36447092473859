<div class="modal-block modal-brand-login" >
  <div class="modal__cont">
    <span class="modal__title">Restore password</span>

    <form [formGroup]="forgetForm" class="form form--w-740" style='flex-direction: column'>

      <div class='select-box' style="margin-bottom: 30px">
        <div class="form__box" [class.error]="forgetForm.get('email').invalid && forgetForm.get('email').touched">
          <label>
            <input type="email" class="form__input" formControlName='email' email='true' placeholder="Enter your email">
          </label>
          <span class='form__error' style="margin-top: 20px; color: red" *ngIf="!forgetForm.get('email').valid && forgetForm.get('email').touched">
            {{forgetForm.controls.email.errors?.errorFromApi? forgetForm.controls.email.errors?.errorFromApi : 'Please enter valid Email'}}
          </span>
        </div>
      </div>

      <button (click)='resetPassword()' type="submit" [disabled]="!forgetForm.valid" class="btn big primary-btn-filled" >
        <span class="btn__text">Restore</span>
      </button>

    </form>
    <!--    <div class='form_main_error' *ngIf='errorMessage'>{{errorMessage}}</div>-->
  </div>

</div>
