<section class="insights-top mt-160">
  <div class="wrapper-small">
    <div class="title-group">
      <h2 class="analytics-title">{{'campaignOverview.analyticsPage.title' | translate}}</h2>
      <p class="title-krona">{{'campaignOverview.analyticsPage.realTime' | translate}}</p>
    </div>
  </div>

  <div class="wrapper">
    <div class="insights-bar-top">
      <div class="insights-bar-top__group">
      </div>

      <div class="insights-bar-top__group">
      </div>
    </div>

    <div class="insights-bar">
      <div class="insights-bar__colum" *ngIf='!hideAnalytics'>
        <span class="insights-bar__title">No. of Creators <!-- <br>score <b>®</b> --> </span>
        <span class="insights-bar__number">{{analytics?.numberCreators || 0}}</span>
        <span class="insights-bar__line"></span>
      </div>

      <div class="insights-bar__colum">
        <span class="insights-bar__title">Like</span>
        <span class="insights-bar__number">{{analytics?.numberLikes || 0}}</span>
        <span class="insights-bar__line"></span>
      </div>

      <div class="insights-bar__colum">
        <span class="insights-bar__title">Comments</span>
        <span class="insights-bar__number">{{analytics?.numberComments || 0}}</span>
        <span class="insights-bar__line"></span>
      </div>

      <div class="insights-bar__colum" *ngIf='!hideAnalytics'>
        <span class="insights-bar__title">No. of Contents</span>
        <span class="insights-bar__number">{{analytics?.numberContents || 0}}</span>
        <span class="insights-bar__line"></span>
      </div>

      <img src="assets/img/insights-bar-bg.png" alt="" class="insights-bar__bg">
    </div>

    <!-- -->


    <div class="insights-bar">
      <div class="insights-bar__colum" *ngIf='!hideAnalytics'>
        <span class="insights-bar__title">Potential reach <!-- <br>score <b>®</b> --> </span>
        <span class="insights-bar__number">{{analytics?.potentialReachTotal || 0}}</span>
        <span class="insights-bar__line"></span>
      </div>

      <div class="insights-bar__colum">
        <span class="insights-bar__title">True reach</span>
        <span class="insights-bar__number">{{analytics?.trueReach || 0}}</span>
        <span class="insights-bar__line"></span>
      </div>

      <div class="insights-bar__colum">
        <span class="insights-bar__title">Impression</span>
        <span class="insights-bar__number">{{analytics?.impression || 0}}</span>
        <span class="insights-bar__line"></span>
      </div>

      <div class="insights-bar__colum">
        <span class="insights-bar__title">Share/Saved</span>
        <span class="insights-bar__number">{{analytics?.shareSaved || 0}}</span>
        <span class="insights-bar__line"></span>
      </div>

      <div class="insights-bar__colum" *ngIf='!hideAnalytics'>
        <span class="insights-bar__title">Total Engagement</span>
        <span class="insights-bar__number">{{analytics?.totalEngagement || 0}}</span>
        <span class="insights-bar__line"></span>
      </div>

      <img src="assets/img/insights-bar-bg.png" alt="" class="insights-bar__bg">
    </div>

    <!-- -->






  </div>
</section>

<section class="audience mt-50">
  <div class="wrapper">

    <div class="audience__colum">
      <h3 class="insights-h3">{{'campaignOverview.analyticsPage.potentialReach' | translate}} {{analytics?.potentialReachTotal}}</h3>
      <canvas baseChart
              [datasets]="potentialReachData"
              [labels]="barChartLabels"
              [options]="barChartOptions"
              [plugins]="barChartPlugins"
              [legend]="barChartLegend"
              [chartType]="barChartType">
      </canvas>
    </div>

    <div class="audience__colum">
      <h3 class="insights-h3">{{'campaignOverview.analyticsPage.numPosts' | translate}}</h3>
      <canvas baseChart
              [datasets]="numberOfPostsData"
              [labels]="barChartLabels"
              [options]="barChartOptions"
              [plugins]="barChartPlugins"
              [legend]="barChartLegend"
              [chartType]="barChartType">
      </canvas>
    </div>

    <div class="audience__colum">
    <h3 class="insights-h3">{{'campaignOverview.analyticsPage.numLikesComments' | translate}}</h3>
    <canvas baseChart
            [datasets]="numberOfLikesCommentsData"
            [labels]="barChartLabels"
            [options]="barChartOptions"
            [plugins]="barChartPlugins"
            [legend]="barChartLegend"
            [chartType]="barChartType">
    </canvas>
    </div>

    <div class="audience__colum">
      <h3 class="insights-h3">{{'campaignOverview.analyticsPage.storiesReach' | translate}}</h3>
      <canvas baseChart
              [datasets]="numberOfStoriesReachImpressions"
              [labels]="barChartLabels"
              [options]="barChartInstagramOptions"
              [plugins]="barChartPlugins"
              [legend]="barChartLegend"
              [chartType]="barChartType">
      </canvas>
    </div>
  </div>
</section>
