<div class="modal modal-reg">
  <div class="modal-reg__header">
    <div class="modal-reg__caption-box">
      <span class="h1">Create account</span>
      <span class="modal-reg__step-text">Step 1</span>
    </div>
    <span class="h2">Create your brand</span>
  </div>
  <div class="modal-reg__main" [formGroup]="formGroup">
    <div class="modal__wrapper">
      <div class="form__box">
        <span class="form__label">Insert brand name</span>
        <input type="text" class="form__input" formControlName="name">
        <span class="form__valid">Incorrect format</span>
      </div>
      <div class="form__box form__two-column">
        <div class="form__upload">
          <span class="form__label">Brand logo</span>
          <label class="form-upload">
            <input type="file" (change)="addImageToUpload($event)" accept=".png,.jpg,.jpeg">
            <span class="form-upload__text">{{ (image) ? image.name : 'Upload from PC' }}</span>
            <img src="assets/icons/folder-open.svg" alt="icon" class="form-upload__icon">
          </label>
          <span class="form-upload__info">png, jpg, jpeg files allowed <br/>600x600 px optimal</span>
        </div>
        <div class="form__upload">
          <img *ngIf="imageUrl" [src]="imageUrl" alt="logo"/>
        </div>
      </div>
    </div>
  </div>
  <button type="button" class="btn btn--bg btn--primary btn--modal" [disabled]="formGroup.invalid" matStepperNext>Next</button>
</div>
