import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PlanDescriptionEnum } from '@app/dialogs/payments-dialog/payments-dialog.component';
import { select, Store } from '@ngrx/store';
import { AppState } from '@store/app.state';
import { selectUserInfo } from '@app/store';

@Component({
  selector: 'app-change-plan-dialog',
  templateUrl: './change-plan-dialog.component.html',
  styleUrls: ['./change-plan-dialog.component.scss']
})
export class ChangePlanDialogComponent implements OnInit {
  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<ChangePlanDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
) {}
  accountPlans: any[] = [];
  selectedPlan = '';
  planDescriptions = PlanDescriptionEnum;
  promocode: string = '';

  // AccountPlan = AccountPlan;

  ngOnInit(): void {
    this.store.pipe(select(selectUserInfo)).subscribe((user) => {
      if (user?.promocode) {
        this.promocode = user.promocode;
        if (this.data.selectedPlan) {
          this.accountPlans = this.data?.accountPlans?.filter((plan) => {
            return !plan?.slug?.includes('test');
          });
        } else {
          this.accountPlans = this.data?.accountPlans;
        }
      } else {
        this.accountPlans = this.data?.accountPlans?.filter((plan) => {
          return !plan?.slug?.includes('test');
        });
      }
    });
    this.selectedPlan = this.data.selectedPlan;
  }

  closeDialog() {
    const isCurrentPlanAvailable = this.accountPlans.find((plan) => plan?.slug === this.selectedPlan);
    const newPlan = isCurrentPlanAvailable ? this.selectedPlan : null;
    this.dialogRef.close({
      plan: newPlan,
      promocode: this.promocode,
    });
  }
}
