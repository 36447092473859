<section class="invite black-bg">
  <div class="wrapper">
    <div class="invite__title-box">
      <h1>Invite influencer</h1>
    </div>
  </div>
  <div class="wrapper-large">
    <div class="invite__cont">
      <span class="h1">Share this code with an influencer:</span>
      <div class="invite__code" >
        <span title="Click to copy code" (click)="copyBrandCode()">{{brandCode}}</span>
      </div>
      <div class="form__box">
        <span class="form__label">Influencer’s email</span>
        <input type="email" [(ngModel)]="inputEmail" class="form__input">
      </div>
      <button (click)="inviteInfluencer(inputEmail)" type="button" class="btn btn--bg btn--primary">Invite</button>
    </div>
  </div>
</section>
