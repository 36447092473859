import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from '@ngneat/dialog';
import { PaymentsDialogComponent } from '@app/dialogs/payments-dialog/payments-dialog.component';
import { ChangePlanDialogComponent } from './change-plan-dialog/change-plan-dialog.component';
import { PlanItemComponentModule } from '@app/shared-features/plan-item/plan-item.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [PaymentsDialogComponent, ChangePlanDialogComponent],
    imports: [CommonModule, DialogModule, PlanItemComponentModule, MatProgressSpinnerModule],
})
export class PaymentsDialogModule {}
