import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Meta, Title } from '@angular/platform-browser';
import { HelperService } from '@app/services/helper.service';
declare var $: any;

enum videoLinks {
  'it' = 'https://flytrendy.com/videos/Influencer-Marketing.mp4',
  'en' = 'https://flytrendy-assets.s3.eu-central-1.amazonaws.com/static/videos/SpotFlyTrendyBrandEN.mp4\n',
}

@Component({
  selector: 'app-platform',
  templateUrl: './platform.component.html',
})
export class PlatformComponent implements OnInit {
  linkToVideo = videoLinks[this.translate.currentLang];
  @ViewChild('video') video: ElementRef<HTMLVideoElement>;

  constructor(
    private title: Title,
    private meta: Meta,
    private helperService: HelperService,
    private translate: TranslateService
  ) {}

  getVideoLink(): void {
    const lang = this.translate.currentLang ? this.translate.currentLang : this.translate.defaultLang;
    this.linkToVideo = videoLinks[lang];
    this.video?.nativeElement.load();
  }

  openDemoDialog(): void {
    this.helperService.openDemoDialog(false);
  }

  ngOnInit(): void {
    this.title.setTitle('Piattaforma SaaS white label per agenzie');

    this.meta.updateTag({
      name: 'keyword',
      content:
        'Piattaforma automatizzata influencer marketing, saas, white label, marginalità, nuovi ricavi, innovativa martech, creator, kpi garantiti, ugc, cpc garantiti, creazione contenuti, attivazione campagna.',
    });

    this.meta.updateTag({
      name: 'description',
      content:
        'Piattaforma SaaS in white label per agenzie per gestione autonoma campagne di Influencer Marketing per i propri clienti',
    });

    this.getVideoLink();
    this.translate.onLangChange.subscribe(() => this.getVideoLink());

    const space = $.animateNumber.numberStepFactories.separator(' ');

    if ($('div').is('#social-media')) {
      $(window).scroll(() => {
        // tslint:disable-next-line:one-variable-per-declaration

        const targetTop = $('#social-media'),
          winHeight = $(window).height(),
          scrollToElem = targetTop?.offset()?.top - winHeight + 270 || 0,
          // tslint:disable-next-line:one-variable-per-declaration
          winScrollTop = $(window).scrollTop();

        if (winScrollTop > scrollToElem) {
          $('#influencers').animateNumber(
            {
              number: 20000,
              numberStep: space,
            },
            {
              duration: 1500,
            }
          );
          $('#instagram-reach').animateNumber(
            {
              number: 53625150,
              numberStep: space,
            },
            {
              duration: 1500,
            }
          );
          $('#facebook-reach').animateNumber(
            {
              number: 5984900,
              numberStep: space,
            },
            {
              duration: 1500,
            }
          );
        }
      });
    }
  }
}
