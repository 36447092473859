import { CampaignModel } from '@app/shared/model/campaign.model';
export const emptyCampaignDraft: CampaignModel = {
  ageMax: 80,
  ageMin: 10,
  brandCompanyName: '',
  callAction: '',
  campaignLink: '',
  campaignLogoUrl: '',
  campaignSpent: 0,
  campaignWallet: 0,
  campaignWalletType: null,
  campaignWalletTypeId: 2,
  contentLove: '',
  createdDatetime: null,
  dontDos: [],
  gender: '',
  heroImageUrl: '',
  id: null,
  language: 'it',
  infoDemographic: 'Italy',
  interests: [],
  linkedinInterests: [],
  isLive: true,
  moodImagesCount: 0,
  moodImages: [],
  openUntilDate: null,
  socialPlatforms: null,
  platformName: null,
  postSubmissions: 0,
  postsAccepted: 0,
  preTags: { facebook: '', instagram: '', tiktok: '' },
  proCategorySecondary: null,
  campaignName: '',
  campaignDescription: '',
  pubGuides: [],
  moodBoardUrls: null,
  publishBriefApp: null,
  responseTime: '',
  responseTimeDate: '',
  reviewed: false,
  subUser: null,
  summarySent: false,
  updatedDate: null,
  userEmail: '',
  objective: 1,
  showPrices: true,
  brandEmail: '',
  reward: {
    cash: true,
    voucher_fixed: null,
    voucher_percentage: null
  },
  vouchers: {
    active: 0,
    total: 0,
  }
};
