import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { getCurrencySymbol } from '@app/shared/helpers/helpers';

@Component({
  selector: 'app-brand-information',
  templateUrl: './brand-information.component.html',
  styleUrls: ['./brand-information.component.scss']
})
export class BrandInformationComponent implements OnInit {
  @Input() isUserExists: boolean;
  @Input() isRequestsOngoing: boolean;
  @Input() formGroup: FormGroup;
  @Input() error: any;
  isDropDownVisible = false;
  getCurrencySymbol = getCurrencySymbol;
  constructor() { }

  ngOnInit(): void {
  }

  toggleDropDown(): void {
    this.isDropDownVisible = !this.isDropDownVisible;
  }
  setCurrency(currency: string, event: any): void {
    event.stopPropagation();
    this.formGroup.patchValue({ currency });
    this.toggleDropDown();
  }
}
