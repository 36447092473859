<div class="modal-reg__header">
  <div class="modal-reg__caption-box">
    <span class="h1">Create account</span>
    <span class="modal-reg__step-text">Step 2</span>
  </div>
  <span class="h2">Your brand information</span>
</div>
<div class="modal-reg__main">
  <div class="modal__wrapper" [formGroup]="formGroup">
    <div class="modal-reg__input-group">
      <div class="form__box"
           [class.error]="formGroup.get('firstName').invalid && formGroup.get('firstName').touched"
           [class.disabled]="isUserExists || isRequestsOngoing"
      >
        <span class="form__label">Name</span>
        <input
          type="text"
          class="form__input"
          formControlName="firstName"
          [readOnly]="isUserExists"
        >
        <span class="form__valid">Incorrect format</span>
      </div>
      <div
        class="form__box"
        [class.error]="formGroup.get('lastName').invalid && formGroup.get('lastName').touched"
        [class.disabled]="isUserExists || isRequestsOngoing"
      >
        <span class="form__label">Surname</span>
        <input
          type="text"
          class="form__input"
          formControlName="lastName"
          [readOnly]="isUserExists"
        >
        <span class="form__valid">Incorrect format</span>
      </div>
    </div>
    <div class="modal-reg__input-group">
      <div
        class="form__box"
        [class.disabled]="isRequestsOngoing"
      >
        <span class="form__label">Currency</span>
        <div class="select__container" (click)="toggleDropDown()">
          <div
            class="form__select"
          >
          <span class="select__value"
                [innerHTML]="getCurrencySymbol(formGroup.get('currency').value) + ' ' + formGroup.get('currency').value"></span>
            <div class="select__drop_down" *ngIf="isDropDownVisible">
              <div
                class="form__select_option"
                [class.selected]="formGroup.get('currency').value === 'EUR'"
                (click)="setCurrency('EUR', $event)"
              >
                &#8364; EUR {{formGroup.get('currency').value === 'EUR' ? '✓ ' : ''}}
              </div>
              <div
                class="form__select_option"
                [class.selected]="formGroup.get('currency').value === 'USD'"
                (click)="setCurrency('USD', $event)"

              >
                &#36; USD {{formGroup.get('currency').value === 'USD' ? '✓ ' : ''}}
              </div>
              <div
                class="form__select_option"
                [class.selected]="formGroup.get('currency').value === 'GBP'"
                (click)="setCurrency('GBP', $event)"
              >
                &#65505; GBP {{formGroup.get('currency').value === 'GBP' ? '✓ ' : ''}}
              </div>
              <div
                class="form__select_option"
                [class.selected]="formGroup.get('currency').value === 'CHF'"
                (click)="setCurrency('CHF', $event)"
              >
                &#8355; CHF {{formGroup.get('currency').value === 'CHF' ? '✓ ' : ''}}
              </div>
              <div
                class="form__select_option"
                [class.selected]="formGroup.get('currency').value === 'CAD'"
                (click)="setCurrency('CAD', $event)"
              >
                &#36; CAD {{formGroup.get('currency').value === 'CAD' ? '✓ ' : ''}}
              </div>
            </div>
            <span class="drop-down-arrow"></span>
          </div>
        </div>
        <span class="form__valid">Incorrect format</span>
      </div>
      <div
        class="form__box"
        [class.error]="formGroup.get('promoCode').invalid && formGroup.get('promoCode').touched"
        [class.disabled]="isUserExists || isRequestsOngoing"
      >
        <span class="form__label">Promo Code</span>
        <input
          type="text"
          class="form__input"
          formControlName="promoCode"
          [readOnly]="isUserExists"
        >
        <span class="form__valid">Promo Code is not valid</span>
      </div>
    </div>
    <div class="modal-reg__row">
      <div class="modal-reg__col">
        <div
          class="form__box"
          [class.error]="formGroup.get('email').invalid && formGroup.get('email').touched || error?.user?.email"
          [class.disabled]="isUserExists || isRequestsOngoing"
        >
          <span class="form__label">Email</span>
          <input
            type="email"
            class="form__input"
            formControlName="email"
            [readOnly]="isUserExists"
          >
          <span class="form__valid" *ngIf="!error?.user?.email">Incorrect format</span>
          <span class="form__valid" *ngIf="error?.user?.email">{{error?.user?.email[0]}}</span>
        </div>
        <div class="form__box"
             [class.error]="formGroup.get('password').invalid && formGroup.get('password').touched"
             [class.disabled]="isUserExists || isRequestsOngoing"
        >
          <span class="form__label">Password</span>
          <input
            type="password"
            class="form__input"
            formControlName="password"
            autocomplete="new-password"
            [readOnly]="isUserExists"
          >
          <span class="form__valid">Incorrect format</span>
        </div>
        <div class="form__box"
             [class.error]="formGroup.get('confirmPassword').invalid && formGroup.get('confirmPassword').touched"
             [class.disabled]="isUserExists || isRequestsOngoing"
        >
          <span class="form__label">Confirm password</span>
          <input
            type="password"
            class="form__input"
            formControlName="confirmPassword"
            [readOnly]="isUserExists"
          >
          <span class="form__valid">Incorrect format</span>
        </div>
      </div>
      <div class="modal-reg__col">
        <div
          class="form__box"
          [class.error]="formGroup.get('country').invalid && formGroup.get('country').touched"
          [class.disabled]="isRequestsOngoing"
        >
          <span class="form__label">Country</span>
          <input type="text" class="form__input" formControlName="country">
          <span class="form__valid">Compulsory field</span>
        </div>
        <div class="modal-reg__input-group">
          <div
            class="form__box"
            [class.error]="formGroup.get('postCode').invalid && formGroup.get('postCode').touched"
            [class.disabled]="isRequestsOngoing"
          >
            <span class="form__label">Postcode</span>
            <input type="text" class="form__input" formControlName="postCode">
            <span class="form__valid">Incorrect format</span>
          </div>
          <div
            class="form__box"
            [class.error]="formGroup.get('city').invalid && formGroup.get('city').touched"
            [class.disabled]="isRequestsOngoing"
          >
            <span class="form__label">City</span>
            <input type="text" class="form__input" formControlName="city">
            <span class="form__valid">Incorrect format</span>
          </div>
        </div>
        <div
          class="form__box"
          [class.error]="formGroup.get('phone').invalid && formGroup.get('phone').touched"
          [class.disabled]="isRequestsOngoing"
        >
          <span class="form__label">Phone number</span>
          <input type="tel" class="form__input" formControlName="phone">
          <span class="form__valid">Incorrect format</span>
        </div>
      </div>
    </div>
    <label class="checked">
      <input type="checkbox" formControlName="agree">
      <span class="checked__circle"></span>
      <span class="checked__text">By signing-up you are agree to our <a routerLink="terms-brand"
                                                                        class="link" target="_blank">Terms&Conditions</a> and  <a
        routerLink="privacy-policy" target="_blank" class="link">Privacy Policy</a></span>
    </label>
  </div>
</div>
