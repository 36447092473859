import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import {selectBrandFunds, selectCampaignDraft} from '@app/store';
import { CampaignModel } from '@app/shared/model';
import { AppState } from '@store/app.state';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import * as campaignDraftActions from '@store/campaign-draft';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import {DialogService} from "@ngneat/dialog";
import {PaypalDialogComponent} from "@app/dialogs/paypal-dialog/paypal-dialog.component";

@Component({
  selector: 'app-campaign-wizard-step',
  templateUrl: './campaign-wizard.component.html',
  styleUrls: ['./campaign-wizard.component.scss'],
})
export class CampaignWizardComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<boolean>();
  @Input() stepTitle: string;
  @Input() enabledNextStep: boolean;
  @Output() previousStepClicked = new EventEmitter();
  @Output() nextStepClicked = new EventEmitter();

  steps: {
    reward: false;
    campaign: false;
    brief: false;
    review: false;
    submit: false;
  };

  isCreatedCampaign = false;
  nextButtonText = '';
  editCampaignName = 'create new campaign';
  submitCampaignProcess = false;
  isEdit = false;
  reviewStep = false;
  private campaignMoodImages = [];
  private brandFunds: number;

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private translate: TranslateService,
    private actions: Actions,
    private toastr: ToastrService,
    private dialog: DialogService
  ) {}

  ngOnInit(): void {
    this.steps = {
      ...this.steps,
      [this.stepTitle]: true,
    };

    if (this.router.routerState.snapshot.url.includes('edit')) {
      this.isEdit = true;
      this.store
        .pipe(select(selectCampaignDraft), takeUntil(this.destroyed$))
        .subscribe((campaignDraft: CampaignModel) => {
          this.editCampaignName = campaignDraft?.campaignName;
          this.isCreatedCampaign = !!campaignDraft?.createdDatetime;
        });
    } else {
      this.store.pipe(select(selectBrandFunds), takeUntil(this.destroyed$)).subscribe((funds) => {
        this.brandFunds = funds;
        console.log(funds)
      });
    }
    if (this.router.routerState.snapshot.url.includes('review')) {
      this.reviewStep = true;
    }
    this.subscribeSubmitCampaign();
    this.subscribeSubmitCampaignSuccess();
    this.subscribeSubmitCampaignFail();

    this.translate
      .get('newCampaign.review.submitBtn')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        const nextButton = this.translate.instant('newCampaign.review.nextBtn');
        const submitButton = this.translate.instant('newCampaign.review.submitBtn');
        this.nextButtonText = this.stepTitle === 'submit' ? submitButton : nextButton;
        if (!this.isEdit) {
          this.editCampaignName = this.translate.instant('newCampaign.newCampaignTitle');
        }

        if (!this.isEdit && this.reviewStep) {
          this.editCampaignName = this.translate.instant('newCampaign.reviewCampaignTitle');
        }
      });
  }
  subscribeSubmitCampaign(): void {
    this.actions
      .pipe(ofType(campaignDraftActions.editCampaignDraftSubmit), takeUntil(this.destroyed$))
      .subscribe(({ campaign }) => {
        if (campaign.moodImages?.length) {
          this.campaignMoodImages = campaign.moodImages.slice();
        }
        this.submitCampaignProcess = true;
      });
  }
  subscribeSubmitCampaignSuccess(): void {
    const submitCampaignSuccess = this.translate.instant('newCampaign.submitMsg.submitEditMsgSuccess');
    this.actions
      .pipe(ofType(campaignDraftActions.editCampaignDraftSubmitSuccess), take(1), takeUntil(this.destroyed$))
      // @ts-ignore
      .subscribe(({ campaign }) => {
        this.campaignMoodImages.forEach(({ image }) => {
          this.store.dispatch(campaignDraftActions.addCampaignMoodImage({ campaignId: campaign.id, image }));
        });
        this.submitCampaignProcess = false;
        this.toastr.success(submitCampaignSuccess);
        if (!this.isCreatedCampaign) {
          if (campaign.reward.cash !== true) {
            const amount = (campaign.reward.voucher_percentage) ?
              campaign.reward.voucher_percentage + ' %' :
              campaign.reward.voucher_fixed + ' EUR';
            // const message = `For this campaign, you must upload vouchers ${amount} discount. <br> Pay attention, the campaign goes live only when there is at least one active voucher.`
            // const dialogRef = this.dialog.success(message);
            // return dialogRef.afterClosed$.subscribe(() => {
            //   this.router.navigate(['admin/vouchers/create']);
            // });
            return this.router.navigate(['admin/vouchers/create']);
          } else {
            if (this.brandFunds === 0) {
              const message = `Please, replenish the balance of your wallet to be able to approve the posts.`
              const dialogRef = this.dialog.success(message);
              return dialogRef.afterClosed$.subscribe(() => {
                this.router.navigate(['campaigns']);
                this.dialog.open(PaypalDialogComponent, {
                  closeButton: true,
                });
              });
            }
          }
        }
        this.router.navigate(['campaigns']);
      });
  }
  subscribeSubmitCampaignFail(): void {
    const submitCampaignFail = this.translate.instant('newCampaign.submitMsg.submitEditMsgError');
    this.actions
      .pipe(ofType(campaignDraftActions.editCampaignDraftSubmitFail), takeUntil(this.destroyed$))
      .subscribe((error) => {
        console.log(error);
        this.submitCampaignProcess = false;
        this.toastr.error(submitCampaignFail);
      });
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  goToPreviousStep(): void {
    if (this.isEdit && this.steps.campaign && this.isCreatedCampaign) {
     this.toastr.warning('You can not edit the reward option of the created campaign!');
      return;
    }
    console.log(this.steps)
    this.previousStepClicked.emit();
  }

  goToNextStep(): void {
    this.nextStepClicked.emit();
  }
}
