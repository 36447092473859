import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '@ngneat/dialog';
import { Meta, Title } from '@angular/platform-browser';
import { HelperService } from '@app/services/helper.service';
declare var $: any;
declare var WOW: any;
declare var Swiper: any;
import { CONFIG } from '@config/configuration';

enum videoLinks {
  'it' = 'https://s3.eu-central-1.amazonaws.com/flytrendy.com/videos/Homepage.mp4',
  'en' = 'https://flytrendy-assets.s3.eu-central-1.amazonaws.com/static/videos/SpotFlyTrendyBrandEN.mp4',
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
  socialLinks = CONFIG.platform.socialLinks;
  linkToVideo = '';
  @ViewChild('brand') brand: ElementRef;
  @ViewChild('video') video: ElementRef<HTMLVideoElement>;

  constructor(
    private dialog: DialogService,
    private title: Title,
    private meta: Meta,
    private helperService: HelperService,
    private translate: TranslateService
  ) {}

  getVideoLink(): void {
    const lang = this.translate.currentLang;
    this.linkToVideo = videoLinks[lang];
    this.video?.nativeElement.load();
  }

  openDemoBrandDialog(): void {
    const isBrand = this.brand.nativeElement.classList.contains('active');
    this.helperService.openDemoDialog(isBrand);
  }
  openDemoDialog(): void {
    this.helperService.openDemoDialog(false);
  }
  ngOnInit(): void {
    // Due to markup implementation we have to use jQuery scripts

    this.title.setTitle('The only Social Creator Platform with guaranteed results!');

    this.translate.onLangChange.subscribe(() => this.getVideoLink());
    this.linkToVideo = videoLinks[this.translate.currentLang] || videoLinks.it;

    /* wow plugin */


    /* header bg */
    function headerBg() {
      const sTop = $(window).scrollTop();

      if (sTop > 1) {
        $("header").addClass("bg");
      } else {
        $("header").removeClass("bg");
      }
    };
    $(window).scroll(function() {
      headerBg();
    })
    $(document).ready(function() {
      headerBg();
    })

    /* video plugin */
    $('video').mediaelementplayer({
      hideVideoControlsOnLoad: true,
    });


    /* wow plugin */
    new WOW().init();


    /* number animation */
    var space = $.animateNumber.numberStepFactories.separator(' ');

    if ($('div').is('#customers')) {
      $(window).scroll(function () {
        if (!$('#customers').offset()) {
          return;
        }

        var targetTop = $('#customers').offset().top,
          winHeight = $(window).height(),
          scrollToElem = targetTop - winHeight + 270,
          winScrollTop = $(window).scrollTop();

        if (winScrollTop > scrollToElem) {
          $('#customers').addClass('active');

          $('#progress-1').animateNumber({
              number: 84,
              numberStep: space
            },
            {
              duration: 3000
            }
          );
          $('#progress-2').animateNumber({
              number: 85,
              numberStep: space
            },
            {
              duration: 3000
            }
          );
          $('#progress-3').animateNumber({
              number: 75,
              numberStep: space
            },
            {
              duration: 3000
            }
          );
        }
      });
    }


    /* menu button - open mobile menu  */
    $(window).scrollHidden = function(comp) {
      if (!$(window).overlay || ($(window).overlay && comp == $(window).overlay)) {
        var coef = document.documentElement.scrollHeight - window.innerHeight,
          bcg = document.querySelector('.bcg-item');

        if (document.body.classList.contains('scroll-hidden')) {
          setTimeout(function () {
            document.body.classList.remove('scroll-hidden');
            document.body.style.marginRight = '0px';

            if (bcg) {
              bcg['style'].width = '100%';
            }
          }, bcg ? 350 : 10)

          $(window).overlay = false;
        } else {
          if (coef > 0) {
            var offset = window.innerWidth - document.body.clientWidth;

            document.body.style.marginRight = offset + 'px';

            if (bcg) {
              bcg['style'].width = 'calc(100% - ' + offset + 'px)';
            }
          }
          document.body.classList.add('scroll-hidden');
          $(window).overlay = comp;
        }
      }
    }
    $(window).resize(function () {
      if (window.innerWidth > 1280) {
        if ( $('.header').hasClass('active') ) {
          $('.header').removeClass('active');
          $('.overlay').fadeOut(300);
          $(window).scrollHidden();
        }
      }
    });
    $('.menu-btn').click(function () {
      $('.header').toggleClass('active');

      if ( $('.header').hasClass('active') ) {
        $('.overlay').fadeIn(300);
        $(window).scrollHidden();
      } else {
        $('.overlay').fadeOut(300);
        $(window).scrollHidden();
      }
    })
    $('.overlay').click(function () {
      if ( $('.header').hasClass('active') ) {
        $('.header').removeClass('active');
        $('.overlay').fadeOut(300);
        $(window).scrollHidden();
      }
    })


  }
}
