<!--<section class="new-campaign mb-100 mt-100">-->
<!--  <div class="wrapper-small">-->
<!--    <h2>{{editCampaignName}}</h2>-->
<!--  </div>-->

<!--  <div class="wrapper">-->
<!--    <div class="wrapper-small">-->
<!--      <div class="steps">-->
<!--        <span class="steps__title" [ngClass]='{"active" : steps?.campaign}'>{{'newCampaign.review.wizzardHeader.campaign' | translate}}</span>-->
<!--        <span class="steps__line"></span>-->
<!--        <span class="steps__title" [ngClass]='{"active" : steps?.brief}'>{{'newCampaign.review.wizzardHeader.brief' | translate}}</span>-->
<!--        <span class="steps__line"></span>-->
<!--        <span class="steps__title" [ngClass]='{"active" : steps?.influencers}'>{{'newCampaign.review.wizzardHeader.influencers' | translate}}</span>-->
<!--        <span class="steps__line"></span>-->
<!--        <span class="steps__title" [ngClass]='{"active" : steps?.review}'>{{'newCampaign.review.wizzardHeader.review' | translate}}</span>-->
<!--        <span class="steps__line"></span>-->
<!--        <span class="steps__title" [ngClass]='{"active" : steps?.submit}'>{{'newCampaign.review.wizzardHeader.submit' | translate}}</span>-->
<!--      </div>-->
<!--    </div>-->

<!--    <img src="assets/img/steps__bg.jpg" alt="" class="steps__bg">-->
<!--  </div>-->


<!--  <div *ngIf="submitCampaignProcess; then spinner else stepView"></div>-->

<!--  <ng-template #spinner>-->
<!--    <div class='progress-spinner'>-->
<!--      <mat-spinner diameter='70'></mat-spinner>-->
<!--    </div>-->
<!--  </ng-template>-->

<!--  <ng-template #stepView>-->
<!--    <ng-content></ng-content>-->

<!--    <div class="wrapper-small">-->
<!--      <div class="new-campaign-form__bottom">-->
<!--        <button (click)='goToPreviousStep()' *ngIf='!steps?.campaign' type="button" class="btn small primary-btn back">-->
<!--          <span class="btn__text">{{'newCampaign.review.goBackBtn' | translate }}</span>-->
<!--        </button>-->

<!--        <button [disabled]='!enabledNextStep' (click)='goToNextStep()' type="button" class="btn small primary-btn-filled next">-->
<!--          <span class="btn__text">{{nextButtonText}}</span>-->
<!--        </button>-->
<!--      </div>-->
<!--    </div>-->
<!--  </ng-template>-->


<!--</section>-->

<section class="builder-hero">
  <div class="wrapper">
    <h1 class="h1">{{editCampaignName}}</h1>
    <div class="tabs">
      <div class="tabs__btn" [ngClass]='{"active" : steps?.reward}'>Influencers reward</div>
      <div class="tabs__btn" [ngClass]='{"active" : steps?.campaign}'>Campaign</div>
      <div class="tabs__btn" [ngClass]='{"active" : steps?.brief}'>Brief</div>
<!--      <div class="tabs__btn" [ngClass]='{"active" : steps?.influencers}'>Influencers</div>-->
      <div class="tabs__btn" [ngClass]='{"active" : steps?.review}'>Review</div>
      <div class="tabs__btn" [ngClass]='{"active" : steps?.submit}'>Submit</div>
    </div>
    <div class="select">
      <select name="tabs">
        <option selected>Influencers reward</option>
        <option>Campaign</option>
        <option>Brief</option>
        <option>Influencers</option>
        <option>Review</option>
        <option>Submit</option>
      </select>
    </div>
  </div>
</section>

  <section class="builder">
    <div class="wrapper-large">

    <div *ngIf="submitCampaignProcess; then spinner else stepView"></div>

      <ng-template #stepView>
        <ng-content></ng-content>
      </ng-template>

      <ng-template #spinner>
        <div class='progress-spinner'>
          <mat-spinner diameter='70'></mat-spinner>
        </div>
      </ng-template>
      <div class="builder__bottom">
        <button (click)='goToPreviousStep()' *ngIf='!steps?.reward' type="button" class="btn btn--br btn--secondary"><span>Back</span></button>
        <button [disabled]='!enabledNextStep || submitCampaignProcess' (click)='goToNextStep()' type="button" class="btn btn--bg btn--primary"><span>{{nextButtonText}}</span></button>
      </div>
    </div>
  </section>
