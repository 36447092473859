import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { selectCampaignAnalytics, selectCampaignOnly, selectUserInfo } from '@app/store';
import { takeUntil } from 'rxjs/operators';
import { AppState } from '@store/app.state';
import { Subject } from 'rxjs';
import { CampaignAnalyticsModel } from '@app/shared/model/campaign-analytics.model';
import { CampaignModel, UserModel } from '@app/shared/model';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { TranslateService } from '@ngx-translate/core';
import { CONFIG } from '@config/configuration';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
})
export class AnalyticsComponent implements OnInit {
  destroyed$ = new Subject<boolean>();
  analytics: CampaignAnalyticsModel;
  campaign: CampaignModel;
  isAdditionalUser = false;
  hideAnalytics = false;
  // @ts-ignore
  // @ts-ignore
  public barChartInstagramOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
          },
          ticks: {
            beginAtZero: true, // @ts-ignore
            stepValue: 1000,
            max: 5000,
          },
        },
      ],
    },
  };

  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    // beginAtZero: true,
  };
  public barChartLabels: Label[] = [this.translate.instant('campaignOverview.analyticsPage.realTime')];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];

  public potentialReachData: ChartDataSets[] = [
    { data: [0], label: '' },
    { data: [0], label: '' },
  ];

  public numberOfPostsData: ChartDataSets[] = [
    { data: [0], label: '' },
    { data: [0], label: '' },
  ];

  public numberOfLikesCommentsData: ChartDataSets[] = [
    { data: [0], label: '' },
    { data: [0], label: '' },
  ];

  public numberOfStoriesReachImpressions: ChartDataSets[] = [
    { data: [0], label: '' },
    { data: [0], label: '' },
  ];

  constructor(private store: Store<AppState>, private translate: TranslateService) {}

  ngOnInit(): void {
    const { platformName } = CONFIG;

    this.store.pipe(select(selectCampaignAnalytics), takeUntil(this.destroyed$)).subscribe((analytics) => {
      this.analytics = analytics;

      if (analytics) {
        this.potentialReachData = [
          {
            data: [this.analytics.potentialReachApproved],
            label: this.translate.instant('campaignOverview.analyticsPage.postsApproved'),
          },
          {
            data: [this.analytics.potentialReachPending],
            label: this.translate.instant('campaignOverview.analyticsPage.postsReview'),
          },
        ];

        this.numberOfPostsData = [
          {
            data: [this.analytics.postsReceived],
            label: this.translate.instant('campaignOverview.analyticsPage.numPostsReceived'),
          },
          {
            data: [this.analytics.postsAccepted],
            label: this.translate.instant('campaignOverview.analyticsPage.numPostsApproved'),
          },
        ];
        this.numberOfLikesCommentsData = [
          {
            data: [this.analytics.numberLikes],
            label: this.translate.instant('campaignOverview.analyticsPage.numLikes'),
          },
          {
            data: [this.analytics.numberComments],
            label: this.translate.instant('campaignOverview.analyticsPage.numComments'),
          },
        ];

        this.numberOfStoriesReachImpressions = [
          {
            data: [this.analytics.storiesReach],
            label: this.translate.instant('campaignOverview.analyticsPage.numStoriesReach'),
          },
          {
            data: [this.analytics.storiesImpressions],
            label: this.translate.instant('campaignOverview.analyticsPage.numStoriesImpressions'),
          },
        ];
      }
    });

    this.store.pipe(select(selectCampaignOnly), takeUntil(this.destroyed$)).subscribe((campaign) => {
      this.campaign = campaign;
    });

    this.store.pipe(select(selectUserInfo), takeUntil(this.destroyed$)).subscribe((user: UserModel) => {
      if (user) {
        // disable analytics for territorial IOL users
        this.isAdditionalUser = !!user.isAdditionalBrandUser;
        if (platformName === 'io_platform' && this.isAdditionalUser) {
          this.hideAnalytics = true;
        }
      }
    });
  }
}
