<!--<div class="modal-block modal-brand-login">-->
<!--  <div class="modal__cont">-->
<!--    <span class="modal__title">Login</span>-->
<!--    -->
<!--    <form [formGroup]="loginForm" (ngSubmit)="onFormSubmit()" class="form form&#45;&#45;w-740">-->
<!--      -->
<!--      <div class="form__group&#45;&#45;two">-->
<!--        <div class="form__box" [class.error]="loginForm.get('username').invalid && loginForm.get('username').touched">-->
<!--          <span class="form__title">Email</span>-->
<!--          <label>-->
<!--            <input type="email" class="form__input" formControlName='username' email='true'>-->
<!--          </label>-->
<!--          <span class='form__error' *ngIf="!loginForm.get('username').valid && loginForm.get('username').touched">Please enter valid Email</span>-->
<!--        </div>-->
<!--      -->
<!--      </div>-->
<!--      -->
<!--      <div class="form__group&#45;&#45;two">-->
<!--        <div class="form__box" [class.error]="loginForm.get('password').invalid && loginForm.get('password').touched">-->
<!--          <span class="form__title">password</span>-->
<!--          <label>-->
<!--            <input type="password" class="form__input" formControlName='password'>-->
<!--          </label>-->
<!--          <span class='form__error' *ngIf="!loginForm.get('password').valid && loginForm.get('password').touched">Please enter Password</span>-->
<!--        </div>-->
<!--        -->
<!--        <button (click)='forgotPassword()' type="button" class="btn-text tertiary-btn-text">-->
<!--          <svg width="17" height="20" viewBox="0 0 17 20" fill="currentColor">-->
<!--            <path-->
<!--              d="M3.24419 7.95563L1.73948 6.45536C0.779033 7.68093 0.181419 9.09667 0 10.5652H2.15568C2.30508 9.64606 2.67859 8.74802 3.24419 7.95563ZM2.15568 12.6783H0C0.181419 14.1469 0.768361 15.5626 1.72881 16.7882L3.23352 15.2879C2.67859 14.4955 2.30508 13.608 2.15568 12.6783ZM3.23352 18.299C4.47144 19.2499 5.91212 19.8204 7.39548 20V17.8553C6.46704 17.6968 5.57062 17.3376 4.77024 16.767L3.23352 18.299ZM9.52982 3.24353V0L4.6742 4.80718L9.52982 9.50872V5.37771C12.5606 5.88484 14.8657 8.48389 14.8657 11.6218C14.8657 14.7596 12.5606 17.3587 9.52982 17.8658V20C13.7451 19.4823 17 15.9324 17 11.6218C17 7.31115 13.7451 3.76123 9.52982 3.24353Z"/>-->
<!--          </svg>-->
<!--          <span class="btn-text__text">forgot password?</span>-->
<!--        </button>-->
<!--      </div>-->
<!--      -->
<!--      <button type="submit" [disabled]="!loginForm.valid" class="btn big primary-btn-filled">-->
<!--        <span class="btn__text">login</span>-->
<!--      </button>-->
<!--    -->
<!--    </form>-->
<!--    <div class='form_main_error' *ngIf='errorMessage'>{{errorMessage}}</div>-->
<!--  </div>-->
<!--</div>-->


<div class="modal modal-login modal-reg">
  <div class="modal-reg__header">
    <div class="modal-reg__caption-box">
      <span class="h1">Login</span>
      <button type="button" class="btn-text btn--tertiary">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.4599 13.7299C13.0118 13.7299 14.2699 12.4718 14.2699 10.9199C14.2699 9.36794 13.0118 8.10986 11.4599 8.10986C9.90798 8.10986 8.6499 9.36794 8.6499 10.9199C8.6499 12.4718 9.90798 13.7299 11.4599 13.7299Z"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M16.65 20.1998C16.65 17.8698 14.33 15.9697 11.46 15.9697C8.59002 15.9697 6.27002 17.8598 6.27002 20.1998"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M21 12.5C21 17.75 16.75 22 11.5 22C6.25 22 2 17.75 2 12.5C2 7.25 6.25 3 11.5 3C12.81 3 14.06 3.25999 15.2 3.73999C15.07 4.13999 15 4.56 15 5C15 5.75 15.21 6.46 15.58 7.06C15.78 7.4 16.04 7.70997 16.34 7.96997C17.04 8.60997 17.97 9 19 9C19.44 9 19.86 8.92998 20.25 8.78998C20.73 9.92998 21 11.19 21 12.5Z"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M23 5C23 5.32 22.96 5.62999 22.88 5.92999C22.79 6.32999 22.63 6.72 22.42 7.06C21.94 7.87 21.17 8.49998 20.25 8.78998C19.86 8.92998 19.44 9 19 9C17.97 9 17.04 8.60997 16.34 7.96997C16.04 7.70997 15.78 7.4 15.58 7.06C15.21 6.46 15 5.75 15 5C15 4.56 15.07 4.13999 15.2 3.73999C15.39 3.15999 15.71 2.64002 16.13 2.21002C16.86 1.46002 17.88 1 19 1C20.18 1 21.25 1.51002 21.97 2.33002C22.61 3.04002 23 3.98 23 5Z"  stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M20.4898 4.97998H17.5098"  stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M19 3.52002V6.51001"  stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span (click)="openRegistrationDialog()">Create account</span>
      </button>
    </div>
    <span class="h2">Enter your profile</span>
  </div>
  <div class="modal-reg__main">
    <form [formGroup]="loginForm" (ngSubmit)="onFormSubmit($event)">
    <div class="modal__wrapper">
        <div class="modal-reg__row">
          <div class="modal-reg__col">
            <div class="form__box">
              <span class="form__label">Email</span>
              <input type="email" class="form__input" formControlName='username' email='true'>
              <span class="form__valid" *ngIf="!loginForm.get('username').valid && loginForm.get('username').touched">Please enter valid Email</span>
            </div>
          </div>
          <div class="modal-reg__col">
            <div class="form__box">
              <span class="form__label">Password</span>
              <input type="password" class="form__input" formControlName='password'>
              <span class="form__valid" *ngIf="!loginForm.get('password').valid && loginForm.get('password').touched">Please, enter password</span>
            </div>
            <button type="button" class="btn-text btn--secondary" (click)='forgotPassword()'>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.02 19.5H7.5C6.88 19.5 6.33 19.48 5.84 19.41C3.21 19.12 2.5 17.88 2.5 14.5V9.5C2.5 6.12 3.21 4.88 5.84 4.59C6.33 4.52 6.88 4.5 7.5 4.5H10.96"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15.02 4.5H16.5C17.12 4.5 17.67 4.52 18.16 4.59C20.79 4.88 21.5 6.12 21.5 9.5V14.5C21.5 17.88 20.79 19.12 18.16 19.41C17.67 19.48 17.12 19.5 16.5 19.5H15.02"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15 2V22"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M11.0946 12H11.1036"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7.0946 12H7.10359"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <span>Forgot your password?</span>
            </button>
          </div>
        </div>
    </div>
    <button type="submit" class="btn btn--bg btn--primary btn--modal" [disabled]="!loginForm.valid">Login</button>
      <div class='form_main_error' style="margin-top: 20px; display: flex; justify-content: center; color: red" *ngIf='errorMessage'>{{errorMessage}}</div>
    </form>
  </div>
</div>
