import { CampaignAnalyticsDto } from '@app/shared/dto/campaign-analytics.dto';
import { CampaignAnalyticsModel } from '@app/shared/model/campaign-analytics.model';

export class CampaignAnalyticsAdapter {
  public static createCampaignAnalytics(analyticsResponse: CampaignAnalyticsDto): CampaignAnalyticsModel {
    if (!analyticsResponse) {
      return null;
    }

    const model = new CampaignAnalyticsModel();
    model.numberCreators = analyticsResponse.number_of_creators;
    model.numberContents = analyticsResponse.number_of_published_content;
    model.trueReach = analyticsResponse.total_true_reach;
    model.impression = analyticsResponse.total_impressions;
    model.totalEngagement = analyticsResponse.total_engagement;
    model.shareSaved = analyticsResponse.total_saved;
    model.numberComments = analyticsResponse.number_comments;
    model.numberLikes = analyticsResponse.number_likes;
    model.postsAccepted = analyticsResponse.number_posts_accepted;
    model.postsReceived = analyticsResponse.number_posts_received;
    model.potentialReachApproved = analyticsResponse.potential_reach_approved;
    model.potentialReachDeclined = analyticsResponse.potential_reach_declined;
    model.potentialReachPublished = analyticsResponse.potential_reach_published;
    model.potentialReachPending = analyticsResponse.potential_reach_pending;
    model.potentialReachUpdated = analyticsResponse.potential_reach_updated;
    model.potentialReachTotal = analyticsResponse.potential_reach_total;
    model.storiesReplies = analyticsResponse.stories_replies;
    model.storiesImpressions = analyticsResponse.stories_impressions;
    model.storiesReach = analyticsResponse.stories_reach;
    return model;
  }
}
