import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './landing/login/login.component';
import { AuthGuard } from './services/auth.guard';
import { NotFoundComponent } from './common/not-found/not-found.component';
import { HomeComponent } from './landing/home/home.component';
import { CampaignsComponent } from './campaigns/campaigns.component';
import { ProfileComponent } from './profile/profile.component';
import { CampaignOverviewComponent } from '@app/campaigns/campaign-overview/campaign-overview.component';
import { CampaignComponent } from '@app/campaign/campaign.component';
import { BriefStepComponent } from '@app/campaign/brief-step/brief-step.component';
import { CampaignStepComponent } from '@app/campaign/campaign-step/campaign-step.component';
import { CampaignDetailsComponent } from '@app/campaigns/campaign-overview/campaign-details/campaign-details.component';
import { InfluencersStepComponent } from '@app/campaign/influencers-step/influencers-step.component';
import { ReviewStepComponent } from '@app/campaign/review-step/review-step.component';
import { SubmitStepComponent } from '@app/campaign/submit-step/submit-step.component';
import { CanDeactivateGuard } from '@app/services/can-deactivate.guard';
import { ContactComponent } from '@app/landing/contact/contact.component';
import { InfluencerComponent } from '@app/landing/influencer/influencer.component';
import { AmplificationComponent } from '@app/landing/amplification/amplification.component';
import { PolicyConditionsComponent } from '@app/landing/policy-conditions/policy-conditions.component';
import { AnalyticsComponent } from '@app/campaigns/campaign-overview/analytics/analytics.component';
import { PostFeedbackComponent } from '@app/common/post-feedback/post-feedback.component';
import { PlatformComponent } from '@app/landing/platform/platform.component';
import { ResultsComponent } from '@app/landing/results/results.component';
import { SaasComponent } from '@app/landing/saas/saas.component';
import { ActivateInfluencerComponent } from '@app/landing/activate-influencer/activate-influencer.component';
import { ChangePasswordComponent } from '@app/landing/change-password/change-password.component';
import { InsightsComponent } from '@app/campaigns/campaign-overview/insights/insights.component';
import { CommunityInsightsComponent } from '@app/community-insights/community-insights.component';
import { PostTabComponent } from '@app/campaigns/campaign-overview/post-tab/post-tab.component';
import {InviteInfluencerComponent} from "@app/invite-influencer/invite-influencer.component";
import {RewardStepComponent} from "@app/campaign/reward-step/reward-step.component";
import {AfterRedirectComponent} from "@app/payments/after-redirect/after-redirect.component";
import {AboutComponent} from "@app/landing/about/about.component";

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  {
    path: 'redirect',
    canActivate: [AuthGuard],
    component: AfterRedirectComponent,
  },
  {
    path: 'campaigns',
    canActivate: [AuthGuard],
    component: CampaignsComponent,
    loadChildren: () => import('./campaigns/campaigns.module').then((m) => m.CampaignsModule),
  },
  {
    path: 'campaigns/:id',
    canActivate: [AuthGuard],
    component: CampaignOverviewComponent,
    children: [
      { path: '', pathMatch: 'full', component: CampaignDetailsComponent },
      { path: 'analytics', pathMatch: 'full', component: AnalyticsComponent },
      {
        path: 'post/:id',
        component: PostTabComponent,
      },
      {
        path: 'edit',
        component: CampaignComponent,
        canDeactivate: [CanDeactivateGuard],
        children: [
          { path: '', pathMatch: 'full', component: CampaignStepComponent },
          {
            path: 'brief',
            component: BriefStepComponent,
          },
          {
            path: 'influencers',
            component: InfluencersStepComponent,
          },
          {
            path: 'review',
            component: ReviewStepComponent,
          },
          {
            path: 'submit',
            component: SubmitStepComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'insights/:id',
    component: InsightsComponent,
  },
  {
    path: 'services/feedback/:id/:email',
    component: PostFeedbackComponent,
  },
  {
    path: 'campaign',
    component: CampaignComponent,
    canDeactivate: [CanDeactivateGuard],
    children: [
      { path: '', pathMatch: 'full', component: RewardStepComponent },
      {
        path: 'campaign',
        component: CampaignStepComponent,
      },
      {
        path: 'brief',
        component: BriefStepComponent,
      },
      {
        path: 'influencers',
        component: InfluencersStepComponent,
      },
      {
        path: 'review',
        component: ReviewStepComponent,
      },
      {
        path: 'submit',
        component: SubmitStepComponent,
      },
    ],
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    component: ProfileComponent,
    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'invite-influencer',
    canActivate: [AuthGuard],
    component: InviteInfluencerComponent,
    loadChildren: () => import('./invite-influencer/invite-influencer.module').then((m) => m.InviteInfluencerModule),
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'community-insights',
    canActivate: [AuthGuard],
    component: CommunityInsightsComponent,
  },
  {
    path: 'contact',
    component: ContactComponent,
    loadChildren: () => import('./landing/contact/contact.module').then((m) => m.ContactModule),
  },
  {
    path: 'influencer',
    component: InfluencerComponent,
    loadChildren: () => import('./landing/influencer/influencer.module').then((m) => m.InfluencerModule),
  },
  {
    path: 'platform',
    component: PlatformComponent,
    loadChildren: () => import('./landing/platform/platform.module').then((m) => m.PlatformModule),
  },
  {
    path: 'results',
    component: ResultsComponent,
    loadChildren: () => import('./landing/results/results.module').then((m) => m.ResultsModule),
  },
  {
    path: 'saas',
    component: SaasComponent,
    loadChildren: () => import('./landing/saas/saas.module').then((m) => m.SaasModule),
  },
  {
    path: 'amplification',
    component: AmplificationComponent,
    loadChildren: () => import('./landing/amplification/amplification.module').then((m) => m.AmplificationModule),
  },
  {
    path: 'activate',
    component: ActivateInfluencerComponent,
    loadChildren: () =>
      import('./landing/activate-influencer/activate-influencer.module').then((m) => m.ActivateInfluencerModule),
  },
  {
    path: 'changepassword',
    component: ChangePasswordComponent,
    loadChildren: () => import('./landing/change-password/change-password.module').then((m) => m.ChangePasswordModule),
  },
  { path: 'cookie-policy', component: PolicyConditionsComponent },
  { path: 'about', component: AboutComponent },
  { path: 'privacy-policy', component: PolicyConditionsComponent },
  { path: 'terms-influencer', component: PolicyConditionsComponent },
  { path: 'terms-brand', component: PolicyConditionsComponent },
  { path: 'login', component: LoginComponent },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
