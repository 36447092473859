<!--<header>-->
<!--  <div class="wrapper">-->
<!--    <div class="header header-left">-->
<!--      <a routerLink='/' class="logo">-->
<!--        <img class="logo__img" [ngClass]='logoClass' [src]="logoUrl" alt="">-->
<!--        <span *ngIf='!brand.isPrivate' class="logo__text">flytrendy</span>-->
<!--      </a>-->

<!--      <div *ngIf='!isFeedbackPage' class="header__block" [ngClass]='{"active": openedMenu}'>-->
<!--        <ul class="menu">-->
<!--          <li class="menu__item">-->
<!--            <a (click)='goToRoute("campaigns")' class="menu__link">Dashboard</a>-->
<!--          </li>-->
<!--          <li class="menu__item">-->
<!--            <a (click)='goToRoute("community-insights")' class="menu__link">Community demographics</a>-->
<!--          </li>-->
<!--        </ul>-->

<!--        <button routerLink='campaign' type="button" class="btn small primary-btn-filled">-->
<!--          <span class="btn__text">{{'topBar.createNewCampaign' | translate}}</span>-->
<!--        </button>-->

<!--        <div class="header__group">-->
<!--          <button type="button" class="btn-text campagin-primary-btn-text" (click)='openPayPalDialog()'>-->
<!--            <span class="btn-text__text">{{brand?.funds}}</span>-->
<!--            <svg class="btn-text__svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor">-->
<!--              <path d="M13.3333 17.2222C10.5444 17.2222 8.13333 15.6444 6.93333 13.3333H13.3333V11.1111H6.2C6.14444 10.7444 6.11111 10.3778 6.11111 10C6.11111 9.62222 6.14444 9.25556 6.2 8.88889H13.3333V6.66667H6.93333C8.13333 4.35556 10.5556 2.77778 13.3333 2.77778C15.1222 2.77778 16.7667 3.43333 18.0333 4.52222L20 2.55556C18.2333 0.966667 15.8889 0 13.3333 0C8.97778 0 5.28889 2.78889 3.91111 6.66667H0V8.88889H3.4C3.35556 9.25556 3.33333 9.62222 3.33333 10C3.33333 10.3778 3.35556 10.7444 3.4 11.1111H0V13.3333H3.91111C5.28889 17.2111 8.97778 20 13.3333 20C15.9 20 18.2333 19.0333 20 17.4444L18.0222 15.4778C16.7667 16.5667 15.1333 17.2222 13.3333 17.2222Z"/>-->
<!--            </svg>-->
<!--          </button>-->

<!--          <button (click)='goToRoute("profile")'  routerLink='profile' type="button" class="btn-text campagin-primary-btn-text">-->
<!--            <span class="btn-text__text" style='text-transform: capitalize'>{{'topBar.myProfile' | translate}}</span>-->
<!--            <svg class="btn-text__svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor">-->
<!--              <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 3C11.66 3 13 4.34 13 6C13 7.66 11.66 9 10 9C8.34 9 7 7.66 7 6C7 4.34 8.34 3 10 3ZM10 17.2C7.5 17.2 5.29 15.92 4 13.98C4.03 11.99 8 10.9 10 10.9C11.99 10.9 15.97 11.99 16 13.98C14.71 15.92 12.5 17.2 10 17.2Z"/>-->
<!--            </svg>-->
<!--          </button>-->

<!--          <button type="button" class="btn-text campagin-primary-btn-text" (click)='logout($event)'>-->
<!--            <span class="btn-text__text">Logout</span>-->
<!--            <svg class="btn-text__svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor">-->
<!--              <path d="M7.87778 13.9889L9.44444 15.5556L15 10L9.44444 4.44444L7.87778 6.01111L10.7444 8.88889H0V11.1111H10.7444L7.87778 13.9889ZM17.7778 0H2.22222C0.988889 0 0 1 0 2.22222V6.66667H2.22222V2.22222H17.7778V17.7778H2.22222V13.3333H0V17.7778C0 19 0.988889 20 2.22222 20H17.7778C19 20 20 19 20 17.7778V2.22222C20 1 19 0 17.7778 0Z"/>-->
<!--            </svg>-->
<!--          </button>-->
<!--        </div>-->

<!--        <div class="soc-seti">-->
<!--          <a [href]="socialLinks.facebook" class="soc-seti__link" target='_blank'>-->
<!--            <img src="assets/img/icons/FB.svg" alt="">-->
<!--          </a>-->
<!--          <a [href]="socialLinks.instagram" class="soc-seti__link" target='_blank'>-->
<!--            <img src="assets/img/icons/INST.svg" alt="">-->
<!--          </a>-->
<!--          <a [href]="socialLinks.linkedin" class="soc-seti__link" target='_blank'>-->
<!--            <img src="assets/img/icons/LIN.svg" alt="">-->
<!--          </a>-->
<!--          <a [href]="socialLinks.youtube" class="soc-seti__link" target='_blank'>-->
<!--            <img src="assets/img/icons/YT.svg" alt="">-->
<!--          </a>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="menu-btn" (click)='toggleMenu()'>-->
<!--        <div class="menu-btn__hamburger" [ngClass]='{"active": openedMenu}'></div>-->
<!--      </div>-->

<!--      <div class="overlay-menu"></div>-->
<!--    </div>-->
<!--  </div>-->
<!--</header>-->
<header>
  <div class="wrapper-large">
    <div class="header">
      <button type="button" class="menu-btn">
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M25.0003 45.8337C36.4587 45.8337 45.8337 36.4587 45.8337 25.0003C45.8337 13.542 36.4587 4.16699 25.0003 4.16699C13.542 4.16699 4.16699 13.542 4.16699 25.0003C4.16699 36.4587 13.542 45.8337 25.0003 45.8337Z" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M19.1045 30.8962L30.8962 19.1045" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M30.8962 30.8962L19.1045 19.1045" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
      <a routerLink='/' class="logo">
        <img src="assets/images/logo.svg" alt="logo">
      </a>
<!--      <div class="social-network">-->
<!--        <a href="#" class="social-network__link" target="_blank">-->
<!--          <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">-->
<!--            <path d="M14.75 22.75H10.25V15.5H9.29999C8.71999 15.5 8.25 15.03 8.25 14.45V12.55C8.25 11.97 8.71999 11.5 9.29999 11.5H10.25V9C10.25 6.93 11.93 5.25 14 5.25H16.7C17.28 5.25 17.75 5.71999 17.75 6.29999V8.70001C17.75 9.28001 17.28 9.75 16.7 9.75H14.75V11.5H16.63C16.95 11.5 17.24 11.64 17.44 11.88C17.64 12.12 17.72 12.44 17.66 12.75L17.28 14.65C17.18 15.14 16.75 15.49 16.25 15.49H14.75V22.75ZM11.75 21.25H13.25V14H15.89L16.09 13H13.26V9.29999C13.26 8.71999 13.73 8.25 14.31 8.25H16.26V6.75H14C12.76 6.75 11.75 7.76 11.75 9V13H9.75V14H11.75V21.25Z"/>-->
<!--            <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"/>-->
<!--          </svg>-->
<!--        </a>-->
<!--        <a href="#" class="social-network__link" target="_blank">-->
<!--          <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">-->
<!--            <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"/>-->
<!--            <path d="M12 16.25C9.66 16.25 7.75 14.34 7.75 12C7.75 9.66 9.66 7.75 12 7.75C14.34 7.75 16.25 9.66 16.25 12C16.25 14.34 14.34 16.25 12 16.25ZM12 9.25C10.48 9.25 9.25 10.48 9.25 12C9.25 13.52 10.48 14.75 12 14.75C13.52 14.75 14.75 13.52 14.75 12C14.75 10.48 13.52 9.25 12 9.25Z"/>-->
<!--            <path d="M17 7.4999C16.87 7.4999 16.74 7.4699 16.62 7.4199C16.5 7.3699 16.39 7.2999 16.29 7.2099C16.2 7.1099 16.12 6.9999 16.07 6.8799C16.02 6.7599 16 6.6299 16 6.4999C16 6.3699 16.02 6.2399 16.07 6.1199C16.13 5.9899 16.2 5.8899 16.29 5.7899C16.34 5.7499 16.39 5.6999 16.44 5.6699C16.5 5.6299 16.56 5.5999 16.62 5.5799C16.68 5.5499 16.74 5.5299 16.81 5.5199C17.13 5.4499 17.47 5.5599 17.71 5.7899C17.8 5.8899 17.87 5.9899 17.92 6.1199C17.97 6.2399 18 6.3699 18 6.4999C18 6.6299 17.97 6.7599 17.92 6.8799C17.87 6.9999 17.8 7.1099 17.71 7.2099C17.61 7.2999 17.5 7.3699 17.38 7.4199C17.26 7.4699 17.13 7.4999 17 7.4999Z"/>-->
<!--          </svg>-->

<!--        </a>-->
<!--        <a href="#" class="social-network__link" target="_blank">-->
<!--          <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">-->
<!--            <path d="M7 5.88C7 5.63467 7.08533 5.42667 7.256 5.256C7.43733 5.08533 7.64533 5 7.88 5C8.11467 5 8.31733 5.08533 8.488 5.256C8.66933 5.42667 8.76 5.63467 8.76 5.88C8.76 6.12533 8.66933 6.33333 8.488 6.504C8.31733 6.67467 8.11467 6.76 7.88 6.76C7.64533 6.76 7.43733 6.67467 7.256 6.504C7.08533 6.33333 7 6.12533 7 5.88ZM8.488 16.744C8.31733 16.9147 8.11467 17 7.88 17C7.64533 17 7.43733 16.9147 7.256 16.744C7.08533 16.5733 7 16.3653 7 16.12V9.56C7 9.31467 7.08533 9.10667 7.256 8.936C7.43733 8.76533 7.64533 8.68 7.88 8.68C8.11467 8.68 8.31733 8.76533 8.488 8.936C8.66933 9.10667 8.76 9.31467 8.76 9.56V16.12C8.76 16.3653 8.66933 16.5733 8.488 16.744Z"/>-->
<!--            <path d="M11.4675 16.76C11.3075 16.92 11.1102 17 10.8755 17C10.6408 17 10.4382 16.92 10.2675 16.76C10.1075 16.6 10.0275 16.4027 10.0275 16.168V9.464C10.0275 9.25067 10.1022 9.06933 10.2515 8.92C10.4115 8.76 10.5982 8.68 10.8115 8.68C11.0248 8.68 11.2062 8.76 11.3555 8.92C11.5155 9.06933 11.6008 9.25067 11.6115 9.464L11.6275 9.704C11.6275 9.71467 11.6328 9.72 11.6435 9.72C11.6542 9.72 11.6648 9.71467 11.6755 9.704C12.4328 8.91467 13.2968 8.52 14.2675 8.52C15.2382 8.52 15.9475 8.79733 16.3955 9.352C16.8435 9.896 17.0675 10.792 17.0675 12.04V16.184C17.0675 16.408 16.9875 16.6 16.8275 16.76C16.6675 16.92 16.4755 17 16.2515 17C16.0275 17 15.8302 16.92 15.6595 16.76C15.4995 16.6 15.4195 16.408 15.4195 16.184V12.28C15.4195 11.32 15.3022 10.6693 15.0675 10.328C14.8328 9.976 14.4062 9.8 13.7875 9.8C13.2862 9.8 12.8115 10.024 12.3635 10.472C11.9262 10.9093 11.7075 11.3787 11.7075 11.88V16.168C11.7075 16.4027 11.6275 16.6 11.4675 16.76Z"/>-->
<!--            <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"/>-->
<!--          </svg>-->
<!--        </a>-->
<!--        <a href="#" class="social-network__link" target="currentColor">-->
<!--          <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">-->
<!--            <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"/>-->
<!--            <path d="M10.59 15.53C10.3 15.53 10.02 15.46 9.77002 15.32C9.19002 14.99 8.84998 14.32 8.84998 13.49V10.53C8.84998 9.69997 9.18002 9.02999 9.77002 8.69999C10.35 8.36999 11.1 8.42996 11.81 8.85996L14.28 10.34C14.96 10.75 15.35 11.36 15.35 12.01C15.35 12.66 14.96 13.27 14.28 13.68L11.81 15.16C11.4 15.4 10.98 15.53 10.59 15.53ZM10.59 9.96998C10.55 9.96998 10.52 9.97997 10.5 9.98997C10.43 10.03 10.35 10.21 10.35 10.52V13.48C10.35 13.78 10.43 13.96 10.5 14.01C10.58 14.05 10.77 14.03 11.03 13.87L13.5 12.39C13.75 12.24 13.84 12.09 13.84 12C13.84 11.91 13.75 11.77 13.5 11.61L11.03 10.13C10.85 10.02 10.69 9.96998 10.59 9.96998Z"/>-->
<!--          </svg>-->
<!--        </a>-->
<!--      </div>-->
      <div class="nav">
        <a routerLink="campaigns" class="nav__link">Campaigns</a>
        <a routerLink="admin" class="nav__link">Administration</a>
        <a routerLink="invite-influencer" class="nav__link">Invite influencer</a>
        <a routerLink="contact" class="nav__link">Contact us</a>
      </div>
      <button class="wallet" (click)='openPayPalDialog()'>
        <img src="assets/icons/empty-wallet.svg" alt="icon" class="wallet__icon">
        <span class="wallet__value" [innerHTML]="currencySymbol"></span>
        <span class="wallet__value">{{brand?.funds}}</span>
      </button>
      <button routerLink='campaign' type="button" class="btn btn--bg btn--primary">New campaign</button>
      <button type="button" class="btn-text btn--primary">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.12 12.78C12.05 12.77 11.96 12.77 11.88 12.78C10.12 12.72 8.71997 11.28 8.71997 9.50998C8.71997 7.69998 10.18 6.22998 12 6.22998C13.81 6.22998 15.28 7.69998 15.28 9.50998C15.27 11.28 13.88 12.72 12.12 12.78Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M18.74 19.3801C16.96 21.0101 14.6 22.0001 12 22.0001C9.40001 22.0001 7.04001 21.0101 5.26001 19.3801C5.36001 18.4401 5.96001 17.5201 7.03001 16.8001C9.77001 14.9801 14.25 14.9801 16.97 16.8001C18.04 17.5201 18.64 18.4401 18.74 19.3801Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span routerLink='profile'  (click)='goToRoute("profile")'>My account</span>
      </button>
    </div>
    <div class="header-mobile">
      <a href="/" class="logo">
        <img src="assets/images/logo.svg" alt="logo">
      </a>
      <button type="button" class="menu-btn">
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.25 14.583H43.75" stroke="currentColor" stroke-width="3" stroke-linecap="round"/>
          <path d="M6.25 25H43.75" stroke="currentColor" stroke-width="3" stroke-linecap="round"/>
          <path d="M6.25 35.417H43.75" stroke="currentColor" stroke-width="3" stroke-linecap="round"/>
        </svg>
      </button>
    </div>
    <div class="overlay" style="display: none"></div>
  </div>
</header>
