<footer>
  <div class="wrapper-large">
    <div class="footer__row">
      <div class="footer__colum">
        <a href="/" class="logo">
          <img src="assets/images/logo.svg" alt="logo">
        </a>
      </div>
      <div class="footer__colum">
        <div class="nav">
          <a href="#" class="nav__link" (click)="openDemoDialog()">Demo</a>
          <a routerLink="/contact" class="nav__link">Contact us</a>
          <a routerLink="/privacy-policy" class="nav__link">Privacy</a>
          <a routerLink="/terms-brand" class="nav__link">Brand T&C'S</a>
          <a routerLink="/terms-influencer" class="nav__link">Influencer T&C'S</a>
        </div>
      </div>
      <div class="footer__colum">
        <div class="footer__group">
<!--          <div class="social-network">-->
<!--            <a href="#" class="social-network__link" target="_blank">-->
<!--              <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path d="M14.75 22.75H10.25V15.5H9.29999C8.71999 15.5 8.25 15.03 8.25 14.45V12.55C8.25 11.97 8.71999 11.5 9.29999 11.5H10.25V9C10.25 6.93 11.93 5.25 14 5.25H16.7C17.28 5.25 17.75 5.71999 17.75 6.29999V8.70001C17.75 9.28001 17.28 9.75 16.7 9.75H14.75V11.5H16.63C16.95 11.5 17.24 11.64 17.44 11.88C17.64 12.12 17.72 12.44 17.66 12.75L17.28 14.65C17.18 15.14 16.75 15.49 16.25 15.49H14.75V22.75ZM11.75 21.25H13.25V14H15.89L16.09 13H13.26V9.29999C13.26 8.71999 13.73 8.25 14.31 8.25H16.26V6.75H14C12.76 6.75 11.75 7.76 11.75 9V13H9.75V14H11.75V21.25Z"/>-->
<!--                <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"/>-->
<!--              </svg>-->
<!--            </a>-->
<!--            <a href="#" class="social-network__link" target="_blank">-->
<!--              <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"/>-->
<!--                <path d="M12 16.25C9.66 16.25 7.75 14.34 7.75 12C7.75 9.66 9.66 7.75 12 7.75C14.34 7.75 16.25 9.66 16.25 12C16.25 14.34 14.34 16.25 12 16.25ZM12 9.25C10.48 9.25 9.25 10.48 9.25 12C9.25 13.52 10.48 14.75 12 14.75C13.52 14.75 14.75 13.52 14.75 12C14.75 10.48 13.52 9.25 12 9.25Z"/>-->
<!--                <path d="M17 7.4999C16.87 7.4999 16.74 7.4699 16.62 7.4199C16.5 7.3699 16.39 7.2999 16.29 7.2099C16.2 7.1099 16.12 6.9999 16.07 6.8799C16.02 6.7599 16 6.6299 16 6.4999C16 6.3699 16.02 6.2399 16.07 6.1199C16.13 5.9899 16.2 5.8899 16.29 5.7899C16.34 5.7499 16.39 5.6999 16.44 5.6699C16.5 5.6299 16.56 5.5999 16.62 5.5799C16.68 5.5499 16.74 5.5299 16.81 5.5199C17.13 5.4499 17.47 5.5599 17.71 5.7899C17.8 5.8899 17.87 5.9899 17.92 6.1199C17.97 6.2399 18 6.3699 18 6.4999C18 6.6299 17.97 6.7599 17.92 6.8799C17.87 6.9999 17.8 7.1099 17.71 7.2099C17.61 7.2999 17.5 7.3699 17.38 7.4199C17.26 7.4699 17.13 7.4999 17 7.4999Z"/>-->
<!--              </svg>-->

<!--            </a>-->
<!--            <a href="#" class="social-network__link" target="_blank">-->
<!--              <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path d="M7 5.88C7 5.63467 7.08533 5.42667 7.256 5.256C7.43733 5.08533 7.64533 5 7.88 5C8.11467 5 8.31733 5.08533 8.488 5.256C8.66933 5.42667 8.76 5.63467 8.76 5.88C8.76 6.12533 8.66933 6.33333 8.488 6.504C8.31733 6.67467 8.11467 6.76 7.88 6.76C7.64533 6.76 7.43733 6.67467 7.256 6.504C7.08533 6.33333 7 6.12533 7 5.88ZM8.488 16.744C8.31733 16.9147 8.11467 17 7.88 17C7.64533 17 7.43733 16.9147 7.256 16.744C7.08533 16.5733 7 16.3653 7 16.12V9.56C7 9.31467 7.08533 9.10667 7.256 8.936C7.43733 8.76533 7.64533 8.68 7.88 8.68C8.11467 8.68 8.31733 8.76533 8.488 8.936C8.66933 9.10667 8.76 9.31467 8.76 9.56V16.12C8.76 16.3653 8.66933 16.5733 8.488 16.744Z"/>-->
<!--                <path d="M11.4675 16.76C11.3075 16.92 11.1102 17 10.8755 17C10.6408 17 10.4382 16.92 10.2675 16.76C10.1075 16.6 10.0275 16.4027 10.0275 16.168V9.464C10.0275 9.25067 10.1022 9.06933 10.2515 8.92C10.4115 8.76 10.5982 8.68 10.8115 8.68C11.0248 8.68 11.2062 8.76 11.3555 8.92C11.5155 9.06933 11.6008 9.25067 11.6115 9.464L11.6275 9.704C11.6275 9.71467 11.6328 9.72 11.6435 9.72C11.6542 9.72 11.6648 9.71467 11.6755 9.704C12.4328 8.91467 13.2968 8.52 14.2675 8.52C15.2382 8.52 15.9475 8.79733 16.3955 9.352C16.8435 9.896 17.0675 10.792 17.0675 12.04V16.184C17.0675 16.408 16.9875 16.6 16.8275 16.76C16.6675 16.92 16.4755 17 16.2515 17C16.0275 17 15.8302 16.92 15.6595 16.76C15.4995 16.6 15.4195 16.408 15.4195 16.184V12.28C15.4195 11.32 15.3022 10.6693 15.0675 10.328C14.8328 9.976 14.4062 9.8 13.7875 9.8C13.2862 9.8 12.8115 10.024 12.3635 10.472C11.9262 10.9093 11.7075 11.3787 11.7075 11.88V16.168C11.7075 16.4027 11.6275 16.6 11.4675 16.76Z"/>-->
<!--                <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"/>-->
<!--              </svg>-->
<!--            </a>-->
<!--            <a href="#" class="social-network__link" target="currentColor">-->
<!--              <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"/>-->
<!--                <path d="M10.59 15.53C10.3 15.53 10.02 15.46 9.77002 15.32C9.19002 14.99 8.84998 14.32 8.84998 13.49V10.53C8.84998 9.69997 9.18002 9.02999 9.77002 8.69999C10.35 8.36999 11.1 8.42996 11.81 8.85996L14.28 10.34C14.96 10.75 15.35 11.36 15.35 12.01C15.35 12.66 14.96 13.27 14.28 13.68L11.81 15.16C11.4 15.4 10.98 15.53 10.59 15.53ZM10.59 9.96998C10.55 9.96998 10.52 9.97997 10.5 9.98997C10.43 10.03 10.35 10.21 10.35 10.52V13.48C10.35 13.78 10.43 13.96 10.5 14.01C10.58 14.05 10.77 14.03 11.03 13.87L13.5 12.39C13.75 12.24 13.84 12.09 13.84 12C13.84 11.91 13.75 11.77 13.5 11.61L11.03 10.13C10.85 10.02 10.69 9.96998 10.59 9.96998Z"/>-->
<!--              </svg>-->
<!--            </a>-->
<!--          </div>-->
          <button *ngIf="!isLoggedIn" (click)='openLoginDialog(); openedMenu = false;' type="button" class="btn-text btn--tertiary">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 10.75C17.59 10.75 17.25 10.41 17.25 10V8C17.25 4.85 16.36 2.75 12 2.75C7.64 2.75 6.75 4.85 6.75 8V10C6.75 10.41 6.41 10.75 6 10.75C5.59 10.75 5.25 10.41 5.25 10V8C5.25 5.1 5.95 1.25 12 1.25C18.05 1.25 18.75 5.1 18.75 8V10C18.75 10.41 18.41 10.75 18 10.75Z"/>
              <path d="M12 19.25C10.21 19.25 8.75 17.79 8.75 16C8.75 14.21 10.21 12.75 12 12.75C13.79 12.75 15.25 14.21 15.25 16C15.25 17.79 13.79 19.25 12 19.25ZM12 14.25C11.04 14.25 10.25 15.04 10.25 16C10.25 16.96 11.04 17.75 12 17.75C12.96 17.75 13.75 16.96 13.75 16C13.75 15.04 12.96 14.25 12 14.25Z"/>
              <path d="M17 22.75H7C2.59 22.75 1.25 21.41 1.25 17V15C1.25 10.59 2.59 9.25 7 9.25H17C21.41 9.25 22.75 10.59 22.75 15V17C22.75 21.41 21.41 22.75 17 22.75ZM7 10.75C3.42 10.75 2.75 11.43 2.75 15V17C2.75 20.57 3.42 21.25 7 21.25H17C20.58 21.25 21.25 20.57 21.25 17V15C21.25 11.43 20.58 10.75 17 10.75H7Z"/>
            </svg>
            <span>Login</span>
          </button>

          <button *ngIf="isLoggedIn" type="button" class="btn-text btn--primary" style="color: black">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.0001 19.2503C10.9801 19.2503 10.0401 18.7903 9.42005 17.9803C9.17005 17.6503 9.23005 17.1803 9.56005 16.9303C9.89005 16.6803 10.3601 16.7403 10.6101 17.0703C10.9401 17.5103 11.4501 17.7503 12.0001 17.7503C12.9601 17.7503 13.7501 16.9603 13.7501 16.0003C13.7501 15.6103 13.6301 15.2403 13.3901 14.9403C13.1401 14.6103 13.2001 14.1403 13.5301 13.8903C13.8601 13.6403 14.3301 13.7003 14.5801 14.0303C15.0201 14.6003 15.2501 15.2803 15.2501 16.0003C15.2501 17.7903 13.7901 19.2503 12.0001 19.2503Z"/>
              <path d="M17 22.75H7C6.63 22.75 6.28 22.74 5.95 22.72C5.54 22.7 5.22 22.34 5.24 21.93C5.26 21.52 5.61 21.22 6.03 21.22C6.33 21.24 6.65 21.24 6.99 21.24H16.99C20.56 21.24 21.24 20.56 21.24 16.99V14.99C21.24 11.48 20.5 10.91 17.95 10.76C17.65 10.74 17.33 10.74 16.99 10.74H7C3.43 10.74 2.75 11.42 2.75 14.99V16.99C2.75 18.73 2.95 19.76 3.41 20.32C3.67 20.64 3.62 21.12 3.29 21.37C2.97 21.63 2.5 21.58 2.24 21.25C1.54 20.4 1.25 19.16 1.25 17V15C1.25 10.59 2.59 9.25 7 9.25H17C17.37 9.25 17.72 9.26 18.04 9.28C22 9.5 22.75 11.46 22.75 15V17C22.75 21.41 21.41 22.75 17 22.75Z"/>
              <path d="M6 10.75C5.59 10.75 5.25 10.41 5.25 10V8C5.25 5.1 5.95 1.25 12 1.25C16.07 1.25 18.18 2.58 18.64 5.44C18.71 5.85 18.43 6.23 18.02 6.3C17.61 6.37 17.23 6.09 17.16 5.68C16.91 4.16 16.12 2.75 12 2.75C7.64 2.75 6.75 4.85 6.75 8V10C6.75 10.41 6.41 10.75 6 10.75Z"/>
              <path d="M1.99994 22.7504C1.80994 22.7504 1.61994 22.6804 1.46994 22.5304C1.17994 22.2404 1.17994 21.7604 1.46994 21.4704L21.4699 1.47043C21.7599 1.18043 22.2399 1.18043 22.5299 1.47043C22.8199 1.76043 22.8199 2.24043 22.5299 2.53043L2.52994 22.5304C2.37994 22.6804 2.18994 22.7504 1.99994 22.7504Z"/>
            </svg>
            <span (click)="logout($event)">Log out</span>
          </button>


        </div>
      </div>
    </div>
    <div class="footer__row">
      <div class="footer__colum">
        <a href="#" class="footer__email">support@socialcollabz.com</a>
      </div>
      <div class="footer__colum">
        <span class="footer__copyright">SocialCollabz - Piazzale Biancamano 8, 20121 Milano – P.IVA 02609800699<br/>Copyright 2023 SocialCollabz</span>
      </div>
      <div class="footer__colum">
        <button type="button" class="btn btn--br btn--primary" routerLink="/cookie-policy">Cookie policy</button>
      </div>
    </div>
  </div>
</footer>
