import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppState} from "@store/app.state";
import {select, Store} from "@ngrx/store";
import {catchError, takeUntil} from "rxjs/operators";
import {BrandModel} from "@app/shared/model";
import {selectBrand} from "@app/store";
import {Subject, throwError} from "rxjs";
import {Clipboard} from '@angular/cdk/clipboard';
import {CommonService} from "@app/services/common.service";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";


@Component({
  selector: 'app-invite-influencer',
  templateUrl: './invite-influencer.component.html',
  styleUrls: ['./invite-influencer.component.scss']
})
export class InviteInfluencerComponent implements OnInit, OnDestroy {

  brandCode: string;
  destroyed$ = new Subject<boolean>();
  inputEmail = '';

  constructor(private store: Store<AppState>,
              private clipboard: Clipboard,
              private commonService: CommonService,
              private toastr: ToastrService,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.store.pipe(select(selectBrand), takeUntil(this.destroyed$)).subscribe((brand: BrandModel) => {
      this.brandCode = brand.brandCode;
    });
  }

  copyBrandCode() {
    this.clipboard.copy(this.brandCode);
  }

  inviteInfluencer(email){
    const errorMsg = this.translate.instant('commonMsg.somethingWentWrong');
    const successMsg = 'The user has been invited!'
    this.commonService
      .inviteInfluencer(email)
      .pipe(
        catchError((err) => {
          this.toastr.error(errorMsg);
          return throwError(err);
        })
      )
      .subscribe(() => {
        this.toastr.success(successMsg);
        this.inputEmail = '';
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

}
