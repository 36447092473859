import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrandInformationComponent } from './brand-information/brand-information.component';
import { ChoosePlanComponent } from '@app/dialogs/registration-dialog/choose-plan/choose-plan.component';
import { CreateBrandComponent } from './create-brand/create-brand.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { RegistrationDialogComponent } from '@app/dialogs/registration-dialog/registration-dialog.component';
import { RouterModule } from '@angular/router';
import { PlanItemComponentModule } from '@app/shared-features/plan-item/plan-item.module';

@NgModule({
  declarations: [RegistrationDialogComponent, ChoosePlanComponent, CreateBrandComponent, BrandInformationComponent],
  imports: [
    MatStepperModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatFormFieldModule,
    RouterModule,
    PlanItemComponentModule
  ],
})
export class RegistrationDialogModule {}
