import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-plan-item',
  templateUrl: './plan-item.component.html',
})
export class PlanItemComponent implements OnInit {
  @Input() currency: string;
  @Input() isActive: boolean;
  @Input() planCost: string;
  @Input() planInfluencers: string;
  @Input() planInfo: string;
  @Input() planSlug: string;
  @Input() planTitle: string;
  @Input() planTooltip: string;

  ngOnInit(): void {
  }

}
