<mat-horizontal-stepper linear #stepper style="overflow: auto">
  <mat-step>
    <app-create-brand [formGroup]="brandForm"></app-create-brand>
  </mat-step>
  <mat-step>
    <div class="modal modal-reg">
      <app-brand-information
        [error]="errorMessage"
        [formGroup]="brandUserForm"
        [isUserExists]="!!profile?.id"
        [isRequestsOngoing]="isRequestsOngoing"
      >
      </app-brand-information>
      <button
        type="button"
              class="btn btn--bg btn--primary btn--modal"
              [disabled]="brandUserForm.invalid || isRequestsOngoing"
              (click)="submitForm()">Next
      </button>
    </div>
  </mat-step>
  <mat-step>
    <app-choose-plan
      [control]="form.get('plan')"
      [accountPlans]="availablePlans"
      [createSubscription]="createSubscription"
    >
    </app-choose-plan>
  </mat-step>
</mat-horizontal-stepper>
