<section class="campaigns black">
  <div class="wrapper">
    <div class="campaigns__header main-header">
      <h1>My campaigns</h1>
      <a routerLink="/campaign">Create campaign</a>
    </div>
    <div class="campaigns__header">
      <h2>Active campaigns</h2>
      <div class="campaigns__number">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
          <path d="M5.15002 22.75C4.74002 22.75 4.40002 22.41 4.40002 22V2C4.40002 1.59 4.74002 1.25 5.15002 1.25C5.56002 1.25 5.90002 1.59 5.90002 2V22C5.90002 22.41 5.56002 22.75 5.15002 22.75Z"/>
          <path d="M16.35 16.75H5.15002C4.74002 16.75 4.40002 16.41 4.40002 16C4.40002 15.59 4.74002 15.25 5.15002 15.25H16.35C17.44 15.25 17.95 14.96 18.05 14.71C18.15 14.46 18 13.9 17.22 13.13L16.02 11.93C15.53 11.5 15.23 10.85 15.2 10.13C15.17 9.37 15.47 8.62 16.02 8.07L17.22 6.87C17.96 6.13 18.19 5.53 18.08 5.27C17.97 5.01 17.4 4.75 16.35 4.75H5.15002C4.73002 4.75 4.40002 4.41 4.40002 4C4.40002 3.59 4.74002 3.25 5.15002 3.25H16.35C18.54 3.25 19.24 4.16 19.47 4.7C19.69 5.24 19.84 6.38 18.28 7.94L17.08 9.14C16.83 9.39 16.69 9.74 16.7 10.09C16.71 10.39 16.83 10.66 17.04 10.85L18.28 12.08C19.81 13.61 19.66 14.75 19.44 15.3C19.21 15.83 18.5 16.75 16.35 16.75Z"/>
        </svg>
        <span>{{activeCampaigns.length}} <b>{{activeCampaigns.length === 1 ? 'campaign' : 'campaigns'}}</b></span>
      </div>
    </div>
    <div class="campaigns__grid">
      <div *ngFor='let campaign of activeCampaigns' [routerLink]="[campaign.id]" class="campaigns__box">
        <div class="campaigns__img">
          <img [src]='campaign.heroImageUrl' [alt]="campaign.campaignName">

          <div class="campaign-box__hover">
            <div class="campaign-box__group">
              <span class="campaign-box__text">{{'campaigns.posts' | translate}}</span>
              <span class="campaign-box__text"><b> {{campaign.postSubmissions}}</b></span>
            </div>
            <div class="campaign-box__group">
              <span class="campaign-box__text">{{'campaigns.accepted' | translate}}</span>
              <span class="campaign-box__text"><b> {{campaign.postsAccepted}}</b></span>
            </div>
            <div class="campaign-box__group">
              <span class="campaign-box__text" [innerHTML]='"campaigns.openUntil" | translate'></span>
              <!--                  <span class="campaign-box__line"></span>-->
              <span class="campaign-box__text"><b> {{campaign.openUntilDate | date: 'dd/MM/yyyy'}}</b></span>
            </div>
          </div>

        </div>


        <span class="campaigns__title">{{campaign.campaignName}}</span>
      </div>
      <h3 *ngIf="activeCampaigns.length === 0">No campaigns found...</h3>

    </div>
    <button type="button" *ngIf="activeCampaigns.length >= 9" class="btn btn--bg btn--primary">Load more</button>
  </div>
</section>

<section class="campaigns">
  <div class="wrapper">
    <div class="campaigns__header">
      <h2>Competed campaigns</h2>
      <div class="campaigns__number">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
          <path d="M5.15002 22.75C4.74002 22.75 4.40002 22.41 4.40002 22V2C4.40002 1.59 4.74002 1.25 5.15002 1.25C5.56002 1.25 5.90002 1.59 5.90002 2V22C5.90002 22.41 5.56002 22.75 5.15002 22.75Z"/>
          <path d="M16.35 16.75H5.15002C4.74002 16.75 4.40002 16.41 4.40002 16C4.40002 15.59 4.74002 15.25 5.15002 15.25H16.35C17.44 15.25 17.95 14.96 18.05 14.71C18.15 14.46 18 13.9 17.22 13.13L16.02 11.93C15.53 11.5 15.23 10.85 15.2 10.13C15.17 9.37 15.47 8.62 16.02 8.07L17.22 6.87C17.96 6.13 18.19 5.53 18.08 5.27C17.97 5.01 17.4 4.75 16.35 4.75H5.15002C4.73002 4.75 4.40002 4.41 4.40002 4C4.40002 3.59 4.74002 3.25 5.15002 3.25H16.35C18.54 3.25 19.24 4.16 19.47 4.7C19.69 5.24 19.84 6.38 18.28 7.94L17.08 9.14C16.83 9.39 16.69 9.74 16.7 10.09C16.71 10.39 16.83 10.66 17.04 10.85L18.28 12.08C19.81 13.61 19.66 14.75 19.44 15.3C19.21 15.83 18.5 16.75 16.35 16.75Z"/>
        </svg>
        <span>{{completedCampaigns.length}} <b>{{completedCampaigns.length === 1 ? 'campaign' : 'campaigns'}}</b></span>
      </div>
    </div>
    <div class="campaigns__grid">
      <div *ngFor='let campaign of completedCampaigns' [routerLink]="[campaign.id]" class="campaigns__box">
        <div class="campaigns__img">
          <img *ngIf="campaign.heroImageUrl" [src]='campaign.heroImageUrl' [alt]="campaign.campaignName">
          <span class="campaigns__grid-no-image" *ngIf="!campaign.heroImageUrl">No campaign image loaded</span>
          <div class="campaign-box__hover">
            <div class="campaign-box__group">
              <span class="campaign-box__text">{{'campaigns.posts' | translate}}</span>
              <span class="campaign-box__text"><b> {{campaign.postSubmissions}}</b></span>
            </div>
            <div class="campaign-box__group">
              <span class="campaign-box__text">{{'campaigns.accepted' | translate}}</span>
              <span class="campaign-box__text"><b> {{campaign.postsAccepted}}</b></span>
            </div>
            <div class="campaign-box__group">
              <span class="campaign-box__text" [innerHTML]='"campaigns.openUntil" | translate'></span>
              <span class="campaign-box__text"><b> {{campaign.openUntilDate | date: 'dd/MM/yyyy'}}</b></span>
            </div>
          </div>

        </div>
        <span class="campaigns__title">{{campaign.campaignName}}</span>
      </div>
      <h3 *ngIf="completedCampaigns.length === 0">No campaigns found...</h3>
    </div>

    <button type="button" *ngIf="activeCampaigns.length >= 9" class="btn btn--bg btn--primary">Load more</button>
  </div>
</section>

<router-outlet></router-outlet>
