<div class="modal modal-payment modal--padding">
  <div class="modal-reg__header">
    <div class="modal-reg__caption-box">
      <span class="h1">Your plan checkout</span>
    </div>
    <span class="h2">You chose <b>{{selectedPlan?.name.toLowerCase()}}</b> plan</span>
    <div class="modal-reg__caption-box">
<!--    <span class="h4 change-plan-button" *ngIf="subscription.status" (click)="openChangePlanDialog()">Change your plan</span>-->
    <span class="h4 change-plan-button" (click)="openChangePlanDialog()">Change your plan</span>
    </div>
  </div>
  <div class="modal-reg__main">
    <div class="modal__wrapper">
      <p class="modal__text"><b>{{planDescription[selectedPlan?.index]}}</b></p>
      <div class="modal-payment__group">
        <span class="modal-payment__title">You will be charged</span>
        <span class="modal-payment__price">
          <b><span class="modal-payment__price" [innerHTML]="currencySymbol"></span>{{selectedPlan?.price}}</b>/month </span>
      </div>
      <div class="modal-payment__group-btn" [class.hidden]="isPaymentOngoing">
        <div id="paypal-button"></div>
      </div>
      <div class='progress-spinner' [class.hidden]="!isPaymentOngoing">
        <mat-spinner diameter='70'></mat-spinner>
      </div>
    </div>
  </div>
</div>
