import { Component, Input, OnInit } from '@angular/core';
import { PlanDescriptionEnum } from '@app/dialogs/payments-dialog/payments-dialog.component';

@Component({
  selector: 'app-choose-plan',
  templateUrl: './choose-plan.component.html',
  styleUrls: ['./choose-plan.component.scss']
})
export class ChoosePlanComponent implements OnInit {

  @Input() control: any;
  @Input() accountPlans: any[] = [];
  @Input() createSubscription: () => void;
  planDescriptions = PlanDescriptionEnum;

  constructor(
  ) { }

  ngOnInit(): void {
  }

}
