import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AppState } from '@store/app.state';
import { selectCampaignDraft } from '@app/store';
import { takeUntil } from 'rxjs/operators';
import { CampaignModel } from '@app/shared/model';
import { Subject } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from '@app/services/helper.service';

@Component({
  selector: 'app-review-step',
  templateUrl: './review-step.component.html',
})
export class ReviewStepComponent implements OnInit, OnDestroy {
  stepTitle = 'review';
  destroyed$ = new Subject<boolean>();
  campaignDraft: CampaignModel;
  moodImages: any[] = [];
  interests: string;
  // linkedinInterests: string;
  pubGuides: string;
  dontDos: string;
  rules: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>,
    private actions: Actions,
    private router: Router,
    private translate: TranslateService,
    private helper: HelperService
  ) {}

  ngOnInit(): void {
    this.store
      .pipe(select(selectCampaignDraft), takeUntil(this.destroyed$))
      .subscribe((campaignDraft: CampaignModel) => {
        if (campaignDraft) {
          this.campaignDraft = campaignDraft;
          this.interests = campaignDraft.interests.join(', ');
          // this.linkedinInterests = campaignDraft.linkedinInterests.join(', ');
          this.pubGuides = campaignDraft && campaignDraft.pubGuides? campaignDraft.pubGuides.join('<br>') : '';
          this.dontDos = campaignDraft && campaignDraft.dontDos? campaignDraft?.dontDos.join('<br>') : '';
          if (campaignDraft.moodImagesCount && !campaignDraft.moodImages?.length) {
            this.getMoodImages(campaignDraft);
            //  this.subscribeToMoodImages();
          }
        }
      });

    this.rules = this.translate.instant('newCampaign.review.houseRules.rules');
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  extractTags(tags: string): string[] {
    return tags.split(' ').filter((v) => v.startsWith('#') || v.startsWith('@'));
  }

  getMoodImages(campaign: Partial<CampaignModel>): void {
    if (campaign.moodImages) {
      this.moodImages = campaign.moodImages.slice();
      return;
    }

    if (campaign.moodBoardUrls && campaign.moodBoardUrls.length) {
      campaign.moodBoardUrls.forEach((moodImageUrl) => {
        this.helper
          .base64Converter(moodImageUrl)
          .then((base64Image) => this.moodImages.push({ image: base64Image }))
          .catch((err) => console.error(err));
      });
    }
  }

  goToPreviousStep(): void {
    this.router.navigate(['../brief'], { relativeTo: this.activatedRoute });
  }

  goToNextStep(): void {
    this.router.navigate(['../submit'], { relativeTo: this.activatedRoute });
  }
}
