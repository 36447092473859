<main>
  <section class="hero">
    <div class="wrapper">
      <div class="hero__col">
        <h1 class="wow fadeInUp">The Only Social Community Reward Platform linked with Social Networks</h1>
        <button type="button" class="btn btn--bg btn--primary wow fadeInUp" data-wow-delay=".1s">Find out more</button>
      </div>
      <div class="hero__img">
        <img src="assets/images/hero-girls.png" alt="hero image">
      </div>
    </div>
  </section>

  <section class="what-sc">
    <div class="wrapper-large">
      <div class="what-sc__wrap">
        <span class="h1 wow fadeInUp" data-wow-offset="100">What is Social Collabz?</span>
        <span class="h2 wow fadeInUp" data-wow-offset="100" data-wow-delay=".1s">Social Collabz is a Revolutionary Digital Platform that connects your Brand with your Community, your real or potential customers, in order to create social content campaigns with Guaranteed Results!</span>
      </div>
      <div class="what-sc__video wow fadeIn" data-wow-offset="150">
        <video width="100%" height="100%" controls preload="auto">
          <source src="assets/video/SocialCollabzEN.mp4" type="video/mp4">
        </video>
      </div>
      <div class="what-sc__wrap">
        <span class="what-sc__title wow fadeInUp" data-wow-offset="100">Activate new Guaranteed Revenue Streams</span>
        <span class="what-sc__text wow fadeInUp" data-wow-offset="100" data-wow-delay=".1s">
          Receive unlimited UGC (User Generated Content) from your Community through our Powerful Technology. Manage and communicate with your community of ambassadors with a unique platform, making your workflow smooth and automated. <br>
          Use your customers’ social content to boost your Brand Awareness and Performance Activity, and reward them with Vouchers thanks to your integrated Loyalty Program. <br><br>
          Your Community will seamlessly grant you access to their First Party Data, thus enabling you to track all content performances in real time in a user friendly manner
        </span>
        <button type="button" class="btn btn--bg btn--primary wow fadeInUp" data-wow-offset="50" data-wow-delay=".15s">Find out more</button>
      </div>
    </div>
  </section>

  <section class="ambassadors">
    <div class="wrapper">
      <div class="ambassadors__wrap">
        <h2 class="h1 wow fadeInUp" data-wow-offset="100">Transform your customers <br/>in loyal brand ambassadors</h2>
        <span class="h2 wow fadeInUp" data-wow-offset="100" data-wow-delay=".1s">Put your community voice at the heart of your business</span>
      </div>
      <div class="ambassadors__grid">
        <div class="ambassadors__box wow fadeInUp" data-wow-offset="100">
          <div class="ambassadors__circle">
            <img src="assets/icons/like-tag.svg" alt="icon">
          </div>
          <span class="ambassadors__text">Grow<br/>genuinely brand advocacy</span>
        </div>
        <div class="ambassadors__box wow fadeInUp" data-wow-offset="100" data-wow-delay=".15s">
          <div class="ambassadors__circle">
            <img src="assets/icons/money-send.svg" alt="icon">
          </div>
          <span class="ambassadors__text">Drive<br/>guaranteed new sales</span>
        </div>
        <div class="ambassadors__box wow fadeInUp" data-wow-offset="100" data-wow-delay=".3s">
          <div class="ambassadors__circle">
            <img src="assets/icons/lovely.svg" alt="icon">
          </div>
          <span class="ambassadors__text">Increase<br/>brand loyalty</span>
        </div>
        <div class="ambassadors__box wow fadeInUp" data-wow-offset="100" data-wow-delay=".45s">
          <div class="ambassadors__circle">
            <img src="assets/icons/people-3.svg" alt="icon">
          </div>
          <span class="ambassadors__text">Build<br/>global customer communities</span>
        </div>
        <div class="ambassadors__box wow fadeInUp" data-wow-offset="100" data-wow-delay=".6s">
          <div class="ambassadors__circle">
            <img src="assets/icons/home-trend-down.svg" alt="icon">
          </div>
          <span class="ambassadors__text">Lower<br/>Service costs</span>
        </div>
      </div>
      <button type="button" class="btn btn--bg btn--primary wow fadeInUp" data-wow-offset="50">Book a free demo now</button>
    </div>
  </section>

  <section class="banner">
    <div class="wrapper-large">
      <div class="banner__img">
        <img src="assets/images/banner-bg.png" alt="banner image">
      </div>
      <div class="banner__col">
        <h2 class="wow fadeInUp" data-wow-offset="100">84% trust a company more if they use real customers in their ads</h2>
        <button type="button" class="btn btn--bg btn--primary wow fadeInUp" data-wow-offset="100" data-wow-delay=".1s">Read more</button>
      </div>
    </div>
  </section>

  <section class="customers">
    <div class="wrapper-large">
      <h1 class="wow fadeInUp" data-wow-offset="100">CUSTOMERS BECOME YOUR MARKETERS</h1>
    </div>
    <div class="wrapper">
      <span class="h1 wow fadeInUp" data-wow-offset="100">How can UGC hero your brand</span>
      <span class="h2 wow fadeInUp" data-wow-offset="100" data-wow-delay=".1s">
        Connect to your customers like never before and use those connections to boost your marketing efforts.   By building a community of brand ambassadors, you will unleash the power of authentic word-of-mouth marketing with user-generated content and boost your sales growth.
        Reward instantly your community's publications with vouchers to be spent on your stores and e-stores, increasing your brand awareness and sales. <br><br>
        <b>All Analytics of your campaigns are displayed in the dashboard</b><br><br>
        84% of customer are rejecting influencer in favor of real voices. <br>
        85% of brand believe on line content communities are a direct route to improving trust <br>
        75% of customers want to be involved in branded content creation.

      </span>
      <div id="customers" class="customers__progress wow fadeInUp" data-wow-offset="150">
        <div class="progress-circle progress-circle--one">
          <span class="progress-circle__number"><b id="progress-1">0</b>%</span>
          <span class="progress-circle__text">OF CUSTOMERS<br/> ARE REJECTING INFLUENCERS<br/> IN FAVOR OF REAL <br/>VOICES</span>
          <svg class="progress-circle__line" width="342" height="342" viewBox="0 0 342 342" stroke="#4BD5CA" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="170.669" cy="171.169" r="153.169" stroke-width="35"/>
          </svg>
        </div>
        <div class="progress-circle progress-circle--two">
          <span class="progress-circle__number"><b id="progress-2">0</b>%</span>
          <span class="progress-circle__text">OF BRANDS BELIEVE<br/> ONLINE CONTENT COMMUNITIES ARE A DIRECT ROUTE TO<br/>IMPROVING TRUST</span>
          <svg class="progress-circle__line" width="342" height="342" viewBox="0 0 342 342" stroke="#4BD5CA" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="170.669" cy="171.169" r="153.169" stroke-width="35"/>
          </svg>
        </div>
        <div class="progress-circle progress-circle--three">
          <span class="progress-circle__number"><b id="progress-3">0</b>%</span>
          <span class="progress-circle__text">OF CUSTOMERS<br/> WANT TO BE INVOLVED IN<br/> BRANDED CONTENT<br/>CREATION</span>
          <svg class="progress-circle__line" width="342" height="342" viewBox="0 0 342 342" stroke="#4BD5CA" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="170.669" cy="171.169" r="153.169" stroke-width="35"/>
          </svg>
        </div>
      </div>
    </div>
  </section>
</main>
