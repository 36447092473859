import * as brandActions from '@store/brand';
import * as userActions from '@store/user';
import { Actions, ofType } from '@ngrx/effects';
import { AppState } from '@store/app.state';
import { BrandModel, UserModel } from '@app/shared/model';
import { BrandService } from '@app/services/brand.service';
import { CommonService } from '@app/services/common.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { PaymentsService } from '@app/services/payments.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '@app/services/user.service';
import { ValidateEqual } from '@app/shared/helpers/validate-equal';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { selectUserInfo } from '@store/user';

@Component({
  selector: 'app-registration-dialog',
  templateUrl: './registration-dialog.component.html',
  styleUrls: ['./registration-dialog.component.scss'],
})
export class RegistrationDialogComponent implements OnInit {
  private destroyed$ = new Subject<boolean>();
  private brand: Partial<BrandModel>;
  public errorMessage: any;
  public availablePlans: any[] = []; // TODO: Type
  public profile: any = {};
  public isRequestsOngoing: boolean = false;
  private isPromoValid: boolean;
  private promoCodeValidated: string;
  @ViewChild('stepper') private myStepper: MatStepper;
  constructor(
    private _formBuilder: FormBuilder,
    private brandService: BrandService,
    private store: Store<AppState>,
    private actions: Actions,
    public ref: DialogRef,
    private userService: UserService,
    private paymentsService: PaymentsService,
    private commonService: CommonService,
    private router: Router,
    private toast: ToastrService,
  ) {}

  selectedPlan: any;

  goForward(){
    this.myStepper.next();
  }

  ngOnInit(): void {
    this.form = this._formBuilder.group({
      plan: ['', [Validators.required]],
    });
    this.brandForm = this._formBuilder.group({
      name: ['', [Validators.required, Validators.min(3)]],
      brandLogo: ['', [Validators.required, Validators.min(3)]],
    });
    this.brandUserForm = this._formBuilder.group({
      firstName: ['', [Validators.required, Validators.min(3)]],
      lastName: ['', [Validators.required, Validators.min(3)]],
      email: ['', [Validators.required, Validators.email]],
      country: ['', [Validators.required, Validators.min(3)]],
      currency: ['EUR', [Validators.required]],
      postCode: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      promoCode: [''],
      city: ['', [Validators.required, Validators.min(3)]],
      phone: ['', [Validators.required, Validators.pattern('[- +()0-9]+')]],
      password: ['', [Validators.required, Validators.min(12)]],
      confirmPassword: ['', [Validators.required, Validators.min(12), ValidateEqual('password')]],
      agree: [null, [Validators.required]],
    });

    const userObservable = this.store.select(selectUserInfo);
    let userInfo;
    const userInfoSubscription = userObservable.pipe(take(1)).subscribe(data => userInfo = data);
    userInfoSubscription.unsubscribe();
      // When user already created, but not a brand. Mock passwords values to validate form. User will proceed only with brand creation.
    // It would be nice to separate user form from brand form in the future or unite logic on backend.
      if (userInfo?.id) {
        this.profile = userInfo;
        this.brandUserForm = this._formBuilder.group({
          firstName: [userInfo.firstName || '', [Validators.required, Validators.min(3)]],
          lastName: [userInfo.lastName || '', [Validators.required, Validators.min(3)]],
          email: [userInfo.email || '', [Validators.required, Validators.email]],
          country: [this.brandUserForm.get('country').value || '', [Validators.required, Validators.min(3)]],
          currency: [this.brandUserForm.get('currency').value || 'EUR', [Validators.required]],
          postCode: [this.brandUserForm.get('postCode').value || '', [Validators.required, Validators.pattern('^[0-9]*$')]],
          city: [this.brandUserForm.get('city').value  || '', [Validators.required, Validators.min(3), Validators.pattern('^([a-zA-Z\u0080-\u024F]+(?:. |-| |\'))*[a-zA-Z\u0080-\u024F]*$')]],
          phone: [this.brandUserForm.get('phone').value  || '', [Validators.required, Validators.pattern('[- +()0-9]+')]],
          password: ['••••••••', [Validators.required, Validators.min(12)]],
          confirmPassword: ['••••••••', [Validators.required, Validators.min(12), ValidateEqual('password')]],
          agree: [true, [Validators.required]],
        });
      }

    this.actions.pipe(ofType(userActions.createNewUserSuccess), takeUntil(this.destroyed$)).subscribe(({ profile }) => {
      if (profile?.id) {
        this.profile = profile;
      }
      setTimeout(() => {
        const brand = this.createBrand(profile);
        this.store.dispatch(brandActions.createNewBrand({ brand }));
      }, 1000);
    });

    this.actions.pipe(ofType(userActions.createNewUserFail), takeUntil(this.destroyed$)).subscribe(({ error }: any) => {
      this.isRequestsOngoing = false;
      const errorMessage = error;
      if (errorMessage) {
        this.errorMessage = errorMessage;
      }
    });

    this.actions.pipe(ofType(brandActions.createNewBrandError), takeUntil(this.destroyed$)).subscribe(({ error }: any) => {
      this.isRequestsOngoing = false;
      const errorMessage = error;
      this.toast.error('Create Brand Error');
      if (errorMessage) {
        this.errorMessage = 'Create Brand Error';
      }
    });

    this.actions.pipe(ofType(brandActions.createNewBrandSuccess), take(1)).subscribe(() => {
      setTimeout(() => {
        this.paymentsService.getPlans().pipe(
          catchError((err) => {
            return throwError(err);
          })
        ).subscribe((res) => {
          if (res?.plan?.length) {
            if (this.isPromoValid) {
              this.availablePlans = res.plan.filter((plan) => {
                return plan?.slug?.includes('test');
              });
            } else {
              this.availablePlans = res.plan.filter((plan) => {
                return !plan?.slug?.includes('test');
              });
            }
          }
        });
      }, 1000);
      setTimeout(() => {
        this.goForward();
      }, 1500);
    });
  }

  form: FormGroup;
  brandForm: FormGroup;
  brandUserForm: FormGroup;

  createBrand(createdUser): any {
    const company = this.brandForm.get('name').value;
    const currency = this.brandUserForm.get('currency').value;
    const locationCity = this.brandUserForm.get('city').value;
    const locationPostCode = this.brandUserForm.get('postCode').value;
    const origin = this.brandUserForm.get('country').value;
    const streetAddress = this.brandUserForm.get('city').value;
    const phone = this.brandUserForm.get('phone').value;
    const brandLogo = this.brandForm.get('brandLogo').value;
    const user = createdUser.id;
    return {
      company,
      currency,
      locationCity,
      locationPostCode,
      streetAddress,
      origin,
      phone,
      brandLogo,
      user,
    };
  }

  submitForm(): void {
    this.isRequestsOngoing = true;
    const promoCode = this.brandUserForm.get('promoCode').value;
    if (promoCode?.length) {
      this.commonService.validatePromoCode(promoCode).subscribe((res) => {
        if (res?.promoCodeValidationView?.message) {
          this.isPromoValid = true;
          this.promoCodeValidated = promoCode;
          this.createUserAndBrand();
        }
      }, () => {
        this.brandUserForm.controls['promoCode'].setErrors({ incorrect: true });
        this.toast.error('Promo Code is not valid');
        this.isRequestsOngoing = false;
      });
    } else {
      this.createUserAndBrand();
    }
  }

  createUserAndBrand(): void {
    if (this.profile?.id) {
      const brand = this.createBrand(this.profile);
      this.store.dispatch(brandActions.createNewBrand({ brand }));
    } else {
      this.createNewUser();
    }
  }

  createNewUser(): void {
    const firstName = this.brandUserForm.get('firstName').value;
    const lastName = this.brandUserForm.get('lastName').value;
    const currency = this.brandUserForm.get('currency').value;
    const username = this.brandUserForm.get('email').value;
    const email = this.brandUserForm.get('email').value;
    const password = this.brandUserForm.get('password').value;
    const promocode = this.brandUserForm.get('promoCode').value;
    const isBrand = true;

    const user = {
      firstName,
      lastName,
      currency,
      username,
      email,
      password,
      isBrand,
      promocode,
    } as Partial<UserModel>;

    this.store.dispatch(userActions.createNewUser({ user }));
  }

  createSubscription = () => {
    this.paymentsService.getPlans().pipe(take(1)).subscribe((res) => {
      const selectedPlanFromForm = this.form.get('plan').value ;
      this.selectedPlan = res.plan.find((plan) => {
        return plan.slug.toLowerCase() === selectedPlanFromForm.toLowerCase();
      });
      const isTest = this.selectedPlan?.slug.includes('test');
      this.paymentsService
        .createSubscription(this.selectedPlan.slug, isTest ? this.promoCodeValidated : null)
        .pipe(take(1))
        .subscribe(() => {
          this.closeDialog();
        });
    });
  }

  closeDialog() {
    this.ref.close();
    this.router.navigate(['/campaign']);
  }
}
